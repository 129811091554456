import React, { ReactElement, useState } from "react";
import "../../components/markdown.css";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import { highlightPlugin } from "@react-pdf-viewer/highlight";
import "@react-pdf-viewer/highlight/lib/styles/index.css";
import { searchPlugin } from "@react-pdf-viewer/search";
import "@react-pdf-viewer/search/lib/styles/index.css";
import { Worker } from "@react-pdf-viewer/core";
import {
    ToolbarProps,
    ToolbarSlot,
    TransformToolbarSlot,
    toolbarPlugin,
} from "@react-pdf-viewer/toolbar";
import { usePermissions, useShow } from "@refinedev/core";
import { EditButton, Show } from "@refinedev/antd";
import { Button, notification } from "antd";
import { CopyFilled, MessageOutlined } from "@ant-design/icons";
import { PanelGroup, Panel, PanelResizeHandle } from "react-resizable-panels";
import { Assistant, ContentType } from "components/assistant";
import { useTranslation } from "react-i18next";
import "./DocumentViewer.css";
import { isMobile } from "react-device-detect";
import { useCurrentTenant } from "tenants";
import { Feature } from "utilities/availableFeatures";

type IContentDocument = {
    id: number;
    uuid: string;
    title: string;
    content: string;
    createdAt: string;
    updatedAt: string;
};

export const ContentDocumentShow = () => {
    const { queryResult } = useShow<IContentDocument>();
    const { data, isLoading } = queryResult;

    // PDF navigator
    const pageNavigationPluginInstance = pageNavigationPlugin();
    const { jumpToPage } = pageNavigationPluginInstance;
    const highlightPluginInstance = highlightPlugin();
    const searchPluginInstance = searchPlugin();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isDocumentLoaded, setDocumentLoaded] = React.useState(false);
    const handleDocumentLoad = () => setDocumentLoaded(true);
    // const { highlight } = searchPluginInstance;

    const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
        ...slot,
        Download: () => <></>,
        DownloadMenuItem: () => <></>,
        EnterFullScreen: () => <></>,
        EnterFullScreenMenuItem: () => <></>,
        SwitchTheme: () => <></>,
        SwitchThemeMenuItem: () => <></>,
        Open: () => <></>,
        Print: () => <></>,
    });

    const toolbarPluginInstance = toolbarPlugin();
    const { renderDefaultToolbar } = toolbarPluginInstance;

    const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
        <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
    );

    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        sidebarTabs: () => [],
        renderToolbar,
    });

    const [isAssistantVisible, setAssistantVisible] = useState(true);

    const copyDocumentPublicLink = () => {
        const publicLink = `${window.location.origin}/document-public/show/${data?.data.uuid}`;
        navigator.clipboard.writeText(publicLink);
        notification.success({
            message: "Public link copied to clipboard",
            description: publicLink,
            duration: 2,
        });
    };
    const { t } = useTranslation();

    const { data: permissionsData } = usePermissions({});

    const {features: tenantFeatures} = useCurrentTenant();

    if (isLoading || !permissionsData) {
        return <div>Loading...</div>;
    }

    const record = data?.data;

    return (
        <div className="overflow-hidden">
            <Show
                breadcrumb={false}
                title={record?.title}
                contentProps={{
                    style: {
                        backgroundColor: "#F5F5F5",
                        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0)",
                        margin: "-24px",
                        marginTop: "-16px",
                    },
                }}
                headerButtons={({ editButtonProps }) => {
                    return (
                        <>
                            {permissionsData?.includes("admin") && (
                                <>
                                    {tenantFeatures.includes(Feature.PublicContentLinks) && (
                                        <Button
                                            size="middle"
                                            onClick={copyDocumentPublicLink}
                                            icon={<CopyFilled />}
                                        >
                                            {t(
                                                "actions.copyPublicLink",
                                                "Copy Public Link"
                                            )}
                                        </Button>
                                    )}
                                    <EditButton {...editButtonProps} />
                                </>
                            )}
                        </>
                    );
                }}
            >
                {isMobile ? (
                    <>
                        <div style={{ height: "calc(100vh - 200px)" }}>
                            {record.document && (
                                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                                    <Viewer
                                        fileUrl={record.document}
                                        plugins={[
                                            pageNavigationPluginInstance,
                                            defaultLayoutPluginInstance,
                                            highlightPluginInstance,
                                            searchPluginInstance,
                                        ]}
                                        onDocumentLoad={handleDocumentLoad}
                                    />
                                </Worker>
                            )}
                        </div>
                        <div
                            className={`fixed inset-0 z-50 bg-gray-200 p-6 w-full ${
                                isAssistantVisible
                                    ? "opacity-100 pointer-events-auto"
                                    : "opacity-0 pointer-events-none"
                            } transition-opacity duration-300`}
                        >
                            <Assistant
                                assistantId={record.assistant}
                                contentType={ContentType.DOCUMENT}
                                contentId={record.id}
                                jumpToPage={jumpToPage}
                                enableHideButton
                                setAssistantVisible={setAssistantVisible}
                                isMobile
                            />
                        </div>
                    </>
                ) : (
                    <PanelGroup
                        direction="horizontal"
                        className="!overflow-visible"
                        style={{ height: "80vh" }}
                    >
                        <Panel id="P-document" minSize={40} defaultSize={65}>
                            {record.document && (
                                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                                    <Viewer
                                        fileUrl={record.document}
                                        plugins={[
                                            pageNavigationPluginInstance,
                                            defaultLayoutPluginInstance,
                                            highlightPluginInstance,
                                            searchPluginInstance,
                                        ]}
                                        onDocumentLoad={handleDocumentLoad}
                                    />
                                </Worker>
                            )}
                            {!record.document && (
                                <div> No document uploaded </div>
                            )}
                        </Panel>
                        {isAssistantVisible && !isMobile && (
                            <>
                                <PanelResizeHandle className="mx-1 w-2 hover:bg-gray-200 transition-colors" />
                                <Panel id="P-assistant" minSize={30}>
                                    <Assistant
                                        assistantId={record.assistant}
                                        contentType={ContentType.DOCUMENT}
                                        contentId={record.id}
                                        jumpToPage={jumpToPage}
                                        enableHideButton
                                        setAssistantVisible={
                                            setAssistantVisible
                                        }
                                    />
                                </Panel>
                            </>
                        )}
                    </PanelGroup>
                )}
            </Show>
            {!isAssistantVisible && record.assistant && (
                <Button
                    type="primary"
                    shape="circle"
                    icon={<MessageOutlined style={{ fontSize: "24px" }} />}
                    size="large"
                    className="fixed bottom-4 right-4 z-50"
                    onClick={() => setAssistantVisible(!isAssistantVisible)}
                    style={{
                        width: "48px",
                        height: "48px",
                    }}
                />
            )}
        </div>
    );
};
