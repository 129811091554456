import { useState } from "react";

import { HttpError, useCreate, useOne, useTranslate, useUpdate } from "@refinedev/core";

import {
    BankOutlined,
    CloseOutlined,
    EditOutlined,
    MailOutlined,
    UserSwitchOutlined,
    LockOutlined,
} from "@ant-design/icons";
import {
    Button,
    Card,
    Drawer,
    Input,
    Select,
    Space,
    Spin,
    Typography,
    Form,
} from "antd";
import { usePermissions } from "@refinedev/core";

import { CustomAvatar } from "../../custom-avatar";
import { SingleElementForm } from "../../single-element-form";
import { Text } from "../../text";
import styles from "./index.module.css";
import { useSelect } from "@refinedev/antd";
import { useTranslation } from "react-i18next";

type Props = {
    opened: boolean;
    setOpened: (opened: boolean) => void;
    userId: number;
};

type FormKeys = "email" | "user_group" | "regular_user_mode";

interface ICompany {
    id: number;
    name: string;
}

export const AccountSettings = ({ opened, setOpened, userId }: Props) => {
    const t = useTranslate();

    const [activeForm, setActiveForm] = useState<FormKeys>();

    const [changePasswordVisible, setChangePasswordVisible] = useState(false);

    const { mutate: updateMutation } = useUpdate();
    interface IChangePassword {
        current_password: string;
        new_password: string;
    }
    const { mutate: updatePassword } = useCreate<IChangePassword>();

    const { data, isLoading, isError } = useOne({
        resource: "users",
        id: userId,
        queryOptions: {
            enabled: opened,
        },
        meta: {
            chatbotId: null,
            subchatbotId: null,
        },
    });

    const { data: userGroupData, isLoading: isUserGroupLoading } = useOne({
        resource: "user-groups",
        id: data?.data?.user_group,
        queryOptions: {
            enabled: !!data?.data?.user_group,
        },
    });

    const { data: permissionsData } = usePermissions({});

    const { selectProps: userGroupSelectProps } = useSelect<ICompany>({
        resource: "user-groups",
        searchField: "name",
        optionLabel: "name",
        debounce: 500,
        defaultValue: data?.data?.user_group,
        pagination: {
            mode: "server",
            current: 1,
            pageSize: 10000,
        },
    });

    const closeModal = () => {
        setOpened(false);
    };

    if (isError) {
        closeModal();
        return null;
    }

    if (isLoading || isUserGroupLoading) {
        return (
            <Drawer
                open={opened}
                width={756}
                styles={{
                    body: {
                        background: "#f5f5f5",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    },
                }}
            >
                <Spin />
            </Drawer>
        );
    }

    const {
        id,
        name,
        email,
        profile_picture: avatarUrl,
        userGroup,
        regular_user_mode,
    } = data?.data ?? {};

    const getActiveForm = (key: FormKeys) => {
        if (activeForm === key) {
            return "form";
        }

        if (!data?.data[key]) {
            return "empty";
        }

        return "view";
    };

    const handleChangePassword = (values: {
        currentPassword: string;
        newPassword: string;
        confirmPassword: string;
    }) => {
        updatePassword({
            resource: "change-password",
            values: {
                current_password: values.currentPassword,
                new_password: values.newPassword,
            },
            successNotification: () => ({
                message: `Password changed successfully`,
                description: "Your password has been updated",
                type: "success",
            }),
            errorNotification: (error: HttpError) => {
                return {
                    message: error.response?.data?.error || error.message,
                    description: "Failed to change password",
                    type: "error",
                };
            },
        });
        setChangePasswordVisible(false);
    };

    return (
        <Drawer
            onClose={closeModal}
            open={opened}
            width={756}
            styles={{
                body: { background: "#f5f5f5", padding: 0 },
                header: { display: "none" },
            }}
        >
            <div className={styles.header}>
                <Text strong>{t("title", "Account Settings")}</Text>
                <Button
                    type="text"
                    icon={<CloseOutlined />}
                    onClick={() => closeModal()}
                />
            </div>
            <div className={styles.container}>
                <div className={styles.name}>
                    <CustomAvatar
                        style={{
                            marginRight: "1rem",
                            flexShrink: 0,
                            fontSize: "40px",
                        }}
                        size={96}
                        src={avatarUrl}
                        name={name}
                    />
                    <Typography.Title
                        level={3}
                        style={{ padding: 0, margin: 0, width: "100%" }}
                        className={styles.title}
                        editable={{
                            onChange(value: string) {
                                updateMutation({
                                    resource: "users",
                                    id: id || -1,
                                    values: { name: value },
                                    mutationMode: "optimistic",
                                    successNotification: false,
                                });
                            },
                            triggerType: ["text", "icon"],
                            icon: (
                                <EditOutlined
                                    className={styles.titleEditIcon}
                                />
                            ),
                        }}
                    >
                        {name}
                    </Typography.Title>
                </div>
                {permissionsData?.includes("admin") ||
                permissionsData?.includes("hidden_admin") ? (
                    <Card
                        title={
                            <Space size={15}>
                                <Text size="sm">
                                    {t("adminInfo", "Admin Information")}
                                </Text>
                            </Space>
                        }
                        styles={{
                            header: { padding: "0 12px" },
                            body: { padding: "0" },
                        }}
                    >
                        <SingleElementForm
                            useFormProps={{
                                id,
                                resource: "users",
                            }}
                            formProps={{ initialValues: { userGroup } }}
                            style={{ borderBottom: "none" }}
                            icon={
                                <BankOutlined
                                    className="tertiary"
                                    style={{
                                        marginTop: "4px",
                                        marginLeft: "4px",
                                    }}
                                />
                            }
                            state={getActiveForm("user_group")}
                            itemProps={{
                                name: "user_group",
                                label: t("group", "Group"),
                            }}
                            view={
                                <Text>
                                    {userGroupData?.data?.name ||
                                        t("unknown", "Unknown")}
                                </Text>
                            }
                            onClick={() => setActiveForm("user_group")}
                            onUpdate={() => setActiveForm(undefined)}
                            onCancel={() => setActiveForm(undefined)}
                        >
                            <Select
                                rootClassName="w-full"
                                {...userGroupSelectProps}
                                defaultValue={userGroupData?.data?.name}
                            />
                        </SingleElementForm>
                        <SingleElementForm
                            useFormProps={{
                                id,
                                resource: "users",
                            }}
                            formProps={{ initialValues: { regular_user_mode } }}
                            style={{ borderBottom: "none" }}
                            icon={
                                <UserSwitchOutlined
                                    className="tertiary"
                                    style={{
                                        marginTop: "4px",
                                        marginLeft: "4px",
                                    }}
                                />
                            }
                            state={getActiveForm("regular_user_mode")}
                            itemProps={{
                                name: "regular_user_mode",
                                label: t(
                                    "regularUserMode",
                                    "Regular User Mode"
                                ),
                            }}
                            view={
                                regular_user_mode ? (
                                    <Text>{t("enabled", "Enabled")}</Text>
                                ) : (
                                    <Text>{t("disabled", "Disabled")}</Text>
                                )
                            }
                            onClick={() => setActiveForm("regular_user_mode")}
                            onUpdate={() => setActiveForm(undefined)}
                            onCancel={() => setActiveForm(undefined)}
                        >
                            <Select
                                defaultValue={
                                    regular_user_mode
                                        ? t("enabled", "Enabled")
                                        : t("disabled", "Disabled")
                                }
                            >
                                <Select.Option value={true}>
                                    {t("enabled", "Enabled")}
                                </Select.Option>
                                <Select.Option value={false}>
                                    {t("disabled", "Disabled")}
                                </Select.Option>
                            </Select>
                        </SingleElementForm>
                    </Card>
                ) : null}
                <Card
                    title={
                        <Space size={15}>
                            <Text size="sm">
                                {t("profileInfo", "Profile Information")}
                            </Text>
                        </Space>
                    }
                    styles={{
                        header: { padding: "0 12px" },
                        body: { padding: "0" },
                    }}
                >
                    <SingleElementForm
                        useFormProps={{
                            id,
                            resource: "users",
                        }}
                        formProps={{ initialValues: { email } }}
                        icon={
                            <MailOutlined
                                className="tertiary"
                                style={{ marginTop: "4px", marginLeft: "4px" }}
                            />
                        }
                        state={getActiveForm("email")}
                        itemProps={{
                            name: "email",
                            label: t("email", "Email"),
                        }}
                        style={{ borderBottom: "none" }}
                        view={<Text>{email}</Text>}
                        onClick={() => setActiveForm("email")}
                        onUpdate={() => setActiveForm(undefined)}
                        onCancel={() => setActiveForm(undefined)}
                    >
                        <Input />
                    </SingleElementForm>
                    <div className="p-4">
                        <Space direction="vertical" className="w-full" size={0}>
                            <div className="flex justify-between items-center">
                                <Space>
                                    <LockOutlined />
                                    <Text className="text-gray-400 ml-2">
                                        {t("password", "Password")}
                                    </Text>
                                </Space>
                                <Button
                                    className="mr-[-4px]"
                                    icon={<EditOutlined />}
                                    onClick={() =>
                                        setChangePasswordVisible(true)
                                    }
                                    style={{
                                        display: changePasswordVisible
                                            ? "none"
                                            : "block",
                                    }}
                                />
                            </div>
                            {!changePasswordVisible ? (
                                <Text className="text-gray-500 ml-8">
                                    ••••••••
                                </Text>
                            ) : (
                                <Form
                                    layout="vertical"
                                    onFinish={handleChangePassword}
                                >
                                    <Form.Item
                                        name="currentPassword"
                                        label={t(
                                            "currentPassword",
                                            "Current Password"
                                        )}
                                        rules={[
                                            {
                                                required: true,
                                                message: t(
                                                    "pleaseEnterCurrentPassword",
                                                    "Please enter your current password"
                                                ),
                                            },
                                        ]}
                                    >
                                        <Input.Password />
                                    </Form.Item>
                                    <Form.Item
                                        name="newPassword"
                                        label={t("newPassword", "New Password")}
                                        rules={[
                                            {
                                                required: true,
                                                message: t(
                                                    "pleaseEnterNewPassword",
                                                    "Please enter your new password"
                                                ),
                                            },
                                        ]}
                                    >
                                        <Input.Password />
                                    </Form.Item>
                                    <Form.Item
                                        name="confirmPassword"
                                        label={t(
                                            "confirmPassword",
                                            "Confirm New Password"
                                        )}
                                        rules={[
                                            {
                                                required: true,
                                                message: t(
                                                    "pleaseConfirmNewPassword",
                                                    "Please confirm your new password"
                                                ),
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (
                                                        !value ||
                                                        getFieldValue(
                                                            "newPassword"
                                                        ) === value
                                                    ) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(
                                                        new Error(
                                                            t(
                                                                "passwordsMustMatch",
                                                                "The two passwords do not match"
                                                            )
                                                        )
                                                    );
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input.Password />
                                    </Form.Item>
                                    <Form.Item>
                                        <div className="flex justify-end">
                                            <Space>
                                                <Button
                                                    onClick={() =>
                                                        setChangePasswordVisible(
                                                            false
                                                        )
                                                    }
                                                >
                                                    {t("cancel", "Cancel")}
                                                </Button>
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                >
                                                    {t(
                                                        "savePassword",
                                                        "Save Password"
                                                    )}
                                                </Button>
                                            </Space>
                                        </div>
                                    </Form.Item>
                                </Form>
                            )}
                        </Space>
                    </div>
                </Card>
            </div>
        </Drawer>
    );
};
