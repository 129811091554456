import Markdown from "react-markdown";
import "../../components/markdown.css";
import { useApiUrl, useCustom, useParsed } from "@refinedev/core";
import { MultipleChoiceAnswerType, MultipleChoiceQuestionType } from "./types";
import { Button, Card, Radio, RadioChangeEvent } from "antd";
import { useEffect, useState } from "react";
import { Assistant, ContentType } from "components/assistant";
import { PanelGroup, Panel } from "react-resizable-panels";

type IQuiz = {
    id: number;
    uuid: string;
};

type IMultipleChoiceQuestion = {
    id: number;
    uuid: string;
};

type UUIDParams = {
    uuid: string;
};

const MultipleChoiceQuestion = ({
    uuid,
    selectedAnswer,
    setSelectedAnswer,
    checkState,
    hintCallback,
    explainCallback,
}: {
    uuid: string;
    selectedAnswer: MultipleChoiceAnswerType | null;
    setSelectedAnswer: (value: MultipleChoiceAnswerType | null) => void;
    checkState: "correct" | "incorrect" | "unanswered";
    hintCallback: (uuid: string) => void;
    explainCallback: (questionUuid: string, answerUuid: string) => void;
}) => {
    const apiUrl = useApiUrl();

    const { data, isLoading } = useCustom<IMultipleChoiceQuestion>({
        url: `${apiUrl}/public/multiple-choice-questions/${uuid}/`,
        method: "get",
    });

    const record = data?.data;

    const handleChange = (e: RadioChangeEvent) => {
        const answer = e.target.value;
        setSelectedAnswer(answer);
    };

    if (isLoading || !record) {
        return null;
    }

    return (
        <div className="mb-8">
            <div className="flex justify-between">
                <h3>{record.title}</h3>
                <div className="flex-1" />
                {checkState === "unanswered" && (
                    <Button
                        size="small"
                        className="mr-4"
                        onClick={() => hintCallback(uuid)}
                    >
                        Hint
                    </Button>
                )}
                {checkState === "correct" && (
                    <p style={{ color: "green" }}>Correct!</p>
                )}
                {checkState === "incorrect" && (
                    <p style={{ color: "red" }}>Incorrect!</p>
                )}
                <div className="mr-4" />
                {(checkState === "correct" || checkState === "incorrect") && (
                    <Button
                        type="primary"
                        className="mr-4"
                        size="small"
                        onClick={() =>
                            explainCallback(uuid, selectedAnswer?.uuid ?? "")
                        }
                    >
                        Explain
                    </Button>
                )}
            </div>
            <p>{record.content}</p>
            <Radio.Group
                value={selectedAnswer}
                onChange={handleChange}
                style={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                {record.answers.map((answer: MultipleChoiceAnswerType) => (
                    <Radio key={answer.uuid} value={answer} className="mb-2">
                        {answer.answer}
                    </Radio>
                ))}
            </Radio.Group>
        </div>
    );
};

export const QuizShowPublic = () => {
    const { params } = useParsed<UUIDParams>();
    const uuid = params?.uuid;

    const apiUrl = useApiUrl();

    const { data, isLoading } = useCustom<IQuiz>({
        url: `${apiUrl}/public/quizzes/${uuid}/`,
        method: "get",
    });

    const record = data?.data;

    const [selectedAnswers, setSelectedAnswers] = useState<{
        [key: string]: MultipleChoiceAnswerType | null;
    }>({});

    const [checkStates, setCheckStates] = useState<{
        [key: string]: "correct" | "incorrect" | "unanswered";
    }>({});

    useEffect(() => {
        if (!record) {
            return;
        }
        setSelectedAnswers(
            record.question_uuids.reduce((acc: { [key: string]: null }, questionUuid: string) => {
                acc[questionUuid] = null;
                return acc;
            }, {})
        );
        setCheckStates(
            record.question_uuids.reduce((acc: string[], questionUuid: number) => {
                acc[questionUuid] = "unanswered";
                return acc;
            }, {})
        );
    }, [record]);

    const handleAnswerSelect = (
        questionId: string,
        answer: MultipleChoiceAnswerType | null
    ) => {
        setSelectedAnswers((prev) => ({ ...prev, [questionId]: answer }));
    };

    const handleCheckAll = () => {
        setCheckStates((prev) => {
            const newCheckStates = { ...prev };
            for (const questionId in selectedAnswers) {
                newCheckStates[questionId] = selectedAnswers[questionId]
                    ?.is_correct
                    ? "correct"
                    : "incorrect";
            }
            return newCheckStates;
        });
    };

    const [onAssistantEvent, setOnAssistantEvent] = useState<{
        type: "explain" | "hint";
        payload: {
            question?: string;
            answer?: string;
            questionTitle?: string;
            questionUuid?: string;
            answerUuid?: string;
        };
    } | null>(null);

    const handleExplain = (questionUuid: string, answerUuid: string) => {
        const question: MultipleChoiceQuestionType = record?.questions.find(
            (question: MultipleChoiceQuestionType) =>
                question.uuid === questionUuid
        );
        setOnAssistantEvent({
            type: "explain",
            payload: {
                questionUuid,
                question: question.content,
                answer: question.answers.find((answer) => answer.is_correct)
                    ?.answer,
                questionTitle: question.title,
                answerUuid: answerUuid,
            },
        });
    };

    const handleHint = (uuid: string) => {
        const question: MultipleChoiceQuestionType = record?.questions.find(
            (question: MultipleChoiceQuestionType) => question.uuid === uuid
        );
        setOnAssistantEvent({
            type: "hint",
            payload: {
                question: question.content,
                questionTitle: question.title,
                questionUuid: question.uuid,
            },
        });
    };

    if (isLoading || !record) {
        return <div>Loading...</div>;
    }

    return (
        <div
            style={{
                height: "100%",
                backgroundColor: "f5f5f5",
            }}
        >
            <PanelGroup
                direction="horizontal"
            >
                <Panel id="P-quiz" defaultSize={60} minSize={30}>
                    <Card>
                        <div
                            className="overflow-y-auto"
                            style={{ height: "90%" }}
                        >
                            <Markdown className="mb-8">
                                {record.description}
                            </Markdown>
                            {record?.question_uuids.map((questionUuid: string) => (
                                <MultipleChoiceQuestion
                                    key={questionUuid}
                                    uuid={questionUuid}
                                    checkState={checkStates[questionUuid]}
                                    hintCallback={handleHint}
                                    explainCallback={handleExplain}
                                    selectedAnswer={selectedAnswers[questionUuid]}
                                    setSelectedAnswer={(answer) =>
                                        handleAnswerSelect(questionUuid, answer)
                                    }
                                />
                            ))}
                        </div>
                        <div className="flex justify-end">
                            <Button
                                onClick={handleCheckAll}
                                type="primary"
                                className="mr-2"
                                disabled={Object.values(selectedAnswers).some(
                                    (answer) => answer === null
                                )}
                            >
                                Check All
                            </Button>
                        </div>
                    </Card>
                </Panel>
                <Panel id="P-assistant" minSize={30}>
                    <Assistant
                        assistantId={0}
                        assistantUuid={record.assistant_uuid}
                        onEvent={onAssistantEvent}
                        contentType={ContentType.QUIZ}
                        contentId={record.id}
                        isPublic
                    />
                </Panel>
            </PanelGroup>
        </div>
    );
};
