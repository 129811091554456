import { Create, useForm } from "@refinedev/antd";
import { ContentPageForm } from "./form";

export const ContentPageCreate = () => {
    const { formProps, saveButtonProps } = useForm();

    return (
        <Create saveButtonProps={saveButtonProps}>
            <ContentPageForm formProps={formProps} isEditing={false} />
        </Create>
    );
};
