import React, { useEffect, useState } from "react";
import {
    useTranslate,
    IResourceComponentsProps,
    useCustomMutation,
    useApiUrl,
    useInvalidate,
    useList,
} from "@refinedev/core";
import {
    List,
    useTable,
    EditButton,
    ShowButton,
    DeleteButton,
} from "@refinedev/antd";
import { Table, Button, Breadcrumb } from "antd";
import { Evaluation } from "./types";
import { renderEvaluationStatusTag } from "components/evaluation/evaluation-status-tag";
import { useNavigate } from "react-router-dom";
import { CreateButton } from "@refinedev/antd";
import { OrderedListOutlined, PlayCircleOutlined } from "@ant-design/icons";
export const EvaluationList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const { tableProps } = useTable<Evaluation>({
        syncWithLocation: true,
    });
    const navigate = useNavigate();
    const t = useTranslate();
    const apiUrl = useApiUrl();
    const { mutate: runEvaluation } = useCustomMutation();
    const invalidate = useInvalidate();
    const [hasInProgressEvaluation, setHasInProgressEvaluation] =
        useState(false);

    const { data: evaluations } = useList<Evaluation>({
        resource: "evaluations",
    });

    useEffect(() => {
        const inProgressEvaluation = evaluations?.data.some(
            (evaluation: Evaluation) => evaluation.status === "in_progress"
        );
        setHasInProgressEvaluation(inProgressEvaluation || false);
    }, [evaluations]);

    useEffect(() => {
        let interval: NodeJS.Timeout;
        if (hasInProgressEvaluation) {
            interval = setInterval(() => {
                invalidate({
                    resource: "evaluations",
                    invalidates: ["list"],
                });
            }, 5000);
        }
        return () => clearInterval(interval);
    }, [hasInProgressEvaluation, invalidate]);

    const breadcrumbItems = [
        {
            title: t("evaluations.title", "Evaluations"),
        },
    ];

    return (
        <List
            breadcrumb={<Breadcrumb items={breadcrumbItems} />}
            headerButtons={({ createButtonProps }) => (
                <>
                    <Button
                        size="middle"
                        onClick={() =>
                            navigate("/evaluation-question-sets/list")
                        }
                        icon={<OrderedListOutlined />}
                    >
                        {t(
                            "evaluations.actions.viewQuestionSets",
                            "View Question Sets"
                        )}
                    </Button>

                    <CreateButton {...createButtonProps} />
                </>
            )}
        >
            <Table {...tableProps} rowKey="id">
                <Table.Column
                    dataIndex="id"
                    title={t("evaluations.fields.id", "ID")}
                />
                <Table.Column
                    dataIndex={["question_set_name"]}
                    title={t("evaluations.fields.questionSet", "Question Set")}
                />
                <Table.Column
                    dataIndex={["assistant_name"]}
                    title={t("evaluations.fields.assistant", "Assistant")}
                />
                <Table.Column
                    dataIndex={["content_document_title"]}
                    title={t("evaluations.fields.document", "Document")}
                />
                <Table.Column
                    dataIndex={["status"]}
                    title={t("evaluations.fields.status", "Status")}
                    render={(value) =>
                        renderEvaluationStatusTag(value, translate)
                    }
                />
                <Table.Column
                    dataIndex={["generated_grade"]}
                    title={t(
                        "evaluations.fields.generatedGrade",
                        "Generated Grade Average"
                    )}
                    render={(value) => `${value ? value.toFixed(1) : 0}%`}
                />
                <Table.Column
                    title={t("table.actions")}
                    dataIndex="actions"
                    align="end"
                    render={(_, record: Evaluation) => (
                        <>
                            <EditButton
                                hideText
                                disabled={record.status !== "draft"}
                                size="small"
                                recordItemId={record.id}
                                className="mr-1"
                            />
                            <ShowButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                                className="mr-1"
                            />
                            <DeleteButton
                                hideText
                                disabled={record.status === "in_progress"}
                                size="small"
                                recordItemId={record.id}
                                className="mr-1"
                            />
                            <Button
                                size="small"
                                disabled={record.status !== "draft"}
                                className="text-lime-500 outline-lime-500 border-lime-500"
                                icon={<PlayCircleOutlined />}
                                onClick={() =>
                                    runEvaluation({
                                        url: `${apiUrl}/evaluations/${record.id}/run-evaluation/`,
                                        method: "post",
                                        values: {}, // Add any values you want to send in the request body
                                        successNotification: () => {
                                            invalidate({
                                                resource: "evaluations",
                                                invalidates: ["list"],
                                            });
                                            return {
                                                message: t(
                                                    "evaluations.notifications.start.success",
                                                    "Evaluation started"
                                                ),
                                                description: t(
                                                    "evaluations.notifications.start.success_description",
                                                    "The evaluation has been started successfully"
                                                ),
                                                type: "success",
                                            };
                                        },
                                        errorNotification: () => ({
                                            message: t(
                                                "evaluations.notifications.start.error",
                                                "Evaluation failed"
                                            ),
                                            description: t(
                                                "evaluations.notifications.start.error_description",
                                                "There was an error starting the evaluation"
                                            ),
                                            type: "error",
                                        }),
                                    })
                                }
                            />
                        </>
                    )}
                />
            </Table>
        </List>
    );
};

export default EvaluationList;
