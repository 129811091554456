import {
    DeleteButton,
    EditButton,
    List,
    ShowButton,
    useTable,
} from "@refinedev/antd";
import { BaseRecord } from "@refinedev/core";
import { Space, Table } from "antd";
import { useTranslation } from "react-i18next";

export const UserGroupList = () => {
    const { tableProps } = useTable({
        syncWithLocation: true,
    });

    const { t } = useTranslation();

    return (
        <List title={t("resources.groups", "Skupine")}>
            <Table {...tableProps} rowKey="id">
                <Table.Column
                    dataIndex="id"
                    title={t("tableHeaders.id", "ID")}
                />
                <Table.Column
                    dataIndex="name"
                    title={t("tableHeaders.name", "Name")}
                />
                <Table.Column
                    dataIndex="users"
                    title={t("tableHeaders.numberOfUsers", "Number of Users")}
                    render={(users) => {
                        return users?.length;
                    }}
                />
                <Table.Column
                    title={t("tableHeaders.usage", "Usage")}
                    render={(item) => {
                        return `${item.usage}/${item.monthly_usage_limit}`;
                    }}
                />
                <Table.Column
                    title={t("tableHeaders.actions", "Actions")}
                    dataIndex="actions"
                    align="right"
                    render={(_, record: BaseRecord) => (
                        <Space>
                            <EditButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                            <ShowButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                            <DeleteButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                        </Space>
                    )}
                />
            </Table>
        </List>
    );
};
