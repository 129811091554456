import { Card, Col, Row } from "antd";
import { Link } from "react-router-dom";
import { ContentVideoType } from "../../pages/content-videos/types";

const VideoThumbnail = ({
    contentVideo,
}: {
    contentVideo: ContentVideoType;
}) => {
    return (
        <Link to={`/content-videos/show/${contentVideo.id}`}>
            <Card>
                <div
                    style={{
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <div
                        style={{
                            position: "relative",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            overflow: "hidden",
                            width: "100%",
                            minHeight: "100px",
                            maxHeight: "200px",
                        }}
                    >
                        {contentVideo.thumbnail && (
                            <img
                                src={contentVideo.thumbnail}
                                alt={contentVideo.title}
                                style={{
                                    objectFit: "cover",
                                    minWidth: "100%",
                                    minHeight: "100%",
                                }}
                            />
                        )}
                    </div>
                </div>

                <h3 className="mt-4">{contentVideo.title}</h3>
            </Card>
        </Link>
    );
};

export const VideoThumbnailList = ({
    contentVideos,
}: {
    contentVideos: ContentVideoType[];
}) => {
    return (
        <Row gutter={[16, 16]}>
            {contentVideos.map((contentVideo: ContentVideoType) => (
                <Col
                    xs={24}
                    sm={24}
                    md={12}
                    xl={8}
                    xxl={6}
                    key={contentVideo.id}
                >
                    <VideoThumbnail contentVideo={contentVideo} />
                </Col>
            ))}
        </Row>
    );
};
