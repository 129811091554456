import { AuthProvider } from "@refinedev/core";
import { AuthActionResponse } from "@refinedev/core/dist/contexts/auth/types";

import { useEffect } from "react";

export const TOKEN_KEY = "token-access";
export const REFRESH_KEY = "token-refresh";
const baseUrl = window.location.origin;
import { getCSRFToken } from './auth/django'
import { notification } from "antd";

interface IUser {
    id: number;
    name: string;
    avatar: string;
    user_group: string;
    is_superuser: boolean;
    is_tenant_admin: boolean;
    regular_user_mode: boolean;
}

export interface IIdentity {
    id: number;
    email: string;
    avatar: string;
    name: string;
    user_group: number;
    is_superuser: boolean;
}

export function useTokenRefresh() {
    useEffect(() => {
        const REFRESH_INTERVAL = 1000 * 60 * 4; // 4 minutes
        const interval = setInterval(() => {
            if (localStorage.getItem("token-refresh")) {
                updateToken();
            }
        }, REFRESH_INTERVAL);
        return () => clearInterval(interval);
    }, []);
}

export async function updateToken(): Promise<boolean> {
    return await fetch(`${baseUrl}/api/token/refresh/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            refresh: localStorage.getItem(REFRESH_KEY),
        }),
    })
        .then((response) => response.json())
        .then((data) => {
            localStorage.setItem(TOKEN_KEY, data.access);
            return true;
        })
        .catch(() => {
            return false;
        });
}

export const authProvider: AuthProvider = {
    login: async ({ email, password }): Promise<AuthActionResponse> => {
        if (email && password) {
            const response = await fetch(`${baseUrl}/api/token/`, {
            // const response = await fetch(`${baseUrl}/_allauth/browser/v1/auth/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": getCSRFToken(),
                },
                body: JSON.stringify({
                    email: email,
                    password: password,
                }),
            });
            const data = await response.json();
            if (response.ok) {
                localStorage.setItem(REFRESH_KEY, data.refresh);
                localStorage.setItem(TOKEN_KEY, data.access);
                return {
                    success: true,
                    redirectTo: "/",
                };
            } else {
                return {
                    success: false,
                    error: {
                        name: "LoginError",
                        message: data.detail,
                    },
                    redirectTo: "/login",
                };
            }
        }
        return {
            success: false,
            error: {
                name: "LoginError",
                message: "Email and password are required",
            },
            redirectTo: "/login",
        };
    },
    logout: async () => {
        localStorage.removeItem(REFRESH_KEY);
        localStorage.removeItem(TOKEN_KEY);
        return {
            success: true,
            redirectTo: "/login",
        };
    },
    check: async () => {
        const token = localStorage.getItem(TOKEN_KEY);
        if (token && token !== "undefined") {
            const refresh = updateToken();
            if (!refresh) {
                return {
                    authenticated: false,
                    redirectTo: "/login",
                };
            }
            return {
                authenticated: true,
            };
        }
        return {
            authenticated: false,
            redirectTo: "/login",
        };
    },
    getIdentity: async () => {
        const response = await fetch(`${baseUrl}/api/identity/get/`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
            },
        });
        if (response.ok) {
            const data = await response.json();
            return {
                id: data.id,
                name: data.name || data.email,
                avatar: data.profile_picture,
                user_group: data.user_group,
                is_superuser: data.is_superuser,
                show_all_companies: data.show_all_companies,
                regular_user_mode: data.regular_user_mode,
            };
        } else {
            return null;
        }
    },
    getPermissions: async () => {
        if (authProvider && authProvider.getIdentity) {
            const user = (await authProvider?.getIdentity()) as IUser;
            if (user && user.is_superuser && !user.regular_user_mode) {
                return ["admin"];
            }
            if (user && user.is_superuser && user.regular_user_mode) {
                return ["user", "hidden_admin"];
            }
        }
        return ["user"];
    },
    onError: async (error) => {
        console.error(error);
        return { error };
    },
    register: async ({ email, password, registration_token, role, name }) => {
        // console.log("Registering user", email, password, registration_token, role, name);

        if (email && password && registration_token) {
            const response = await fetch(`${baseUrl}/api/_allauth/browser/v1/auth/signup`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": getCSRFToken(),
                },
                body: JSON.stringify({
                    email,
                    password,
                    registration_token: registration_token,
                    role,
                    name
                }),
            });

            const data = await response.json();
            if (response.ok) {
                localStorage.setItem(REFRESH_KEY, data.refresh);
                localStorage.setItem(TOKEN_KEY, data.access);
                notification.success({
                    message: "Registration successful",
                    description: "You have been registered. Please log in."
                });
                return {
                    success: true,
                    redirectTo: "/",
                };
            } else {
                return {
                    success: false,
                    error: {
                        name: "RegisterError",
                        message: data.detail,
                    },
                };
            }
        }

        return {
            success: false,
            error: {
                name: "UnfilledFormError",
                message: "Please fill out the form completely",
            }
        }
    },
    forgotPassword: async ({ email }) => {
        // send password reset link to the user's email address here

        console.log("Forgot password for user", email)

        // if request is successful
        return {
            success: true,
            redirectTo: "/login",
        };

        // if request is not successful
        return {
            success: false,
            error: {
                name: "Forgot Password Error",
                message: "Email address does not exist",
            },
        };
    },
};
