import { Create, useForm } from "@refinedev/antd";
import { ContentVideoForm } from "./form";

export const ContentVideoCreate = () => {
    const { formProps, saveButtonProps } = useForm();

    return (
        <Create saveButtonProps={saveButtonProps}>
            <ContentVideoForm data={undefined} formProps={formProps} />
        </Create>
    );
};
