import { Create, useForm } from "@refinedev/antd";
import { CaseForm } from "./form";

export const CaseCreate = () => {
    const { formProps, saveButtonProps } = useForm({
        redirect: "edit"
    });

    return (
        <Create saveButtonProps={saveButtonProps}>
            <CaseForm formProps={formProps} data={undefined} />
        </Create>
    );
};
