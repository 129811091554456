import Markdown from "react-markdown";
import "../../components/markdown.css";
import { EditButton, Show, useForm, AutoSaveIndicator } from "@refinedev/antd";
import { useCreate, useShow } from "@refinedev/core";
import { CaseQuestionType } from "./types";
import {
    Button,
    Card,
    notification,
    Tabs,
    Divider,
    Form,
    Input,
    Tag,
    Spin,
} from "antd";
import { useEffect, useState } from "react";
import { Assistant, ContentType } from "components/assistant";
import { PanelGroup, Panel, PanelResizeHandle } from "react-resizable-panels";
import { CopyFilled, MessageOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useInvalidate } from "@refinedev/core";

const CaseQuestion = ({
    question,
    caseAttemptId,
}: {
    question: CaseQuestionType;
    caseAttemptId: number;
}) => {
    // try to get the existing answer
    const { queryResult: caseAnswerQueryResult } = useShow({
        resource: `case-question-attempts/attempt/${caseAttemptId}/question`,
        id: question.id,
    });

    const { data: caseAnswerData } = caseAnswerQueryResult;

    const { formProps, autoSaveProps, form } = useForm({
        autoSave: {
            enabled: true,
            debounce: 500,
        },
        action: "edit",
        resource: "case-question-attempts",
        id: caseAnswerData?.data?.id,
        successNotification: false,
    });

    const Score = () => {
        if (caseAnswerData?.data?.score !== null && caseAnswerData?.data?.score !== undefined) {
            return (
                <div>
                    <h3>Score</h3>
                    <p>{caseAnswerData?.data?.score}/100</p>
                </div>
            );
        }
        return null;
    };

    const Feedback = () => {
        if (caseAnswerData?.data?.feedback) {
            return (
                <>
                    <Divider />
                    <h3>Feedback</h3>
                    <Markdown>{caseAnswerData?.data?.feedback}</Markdown>
                </>
            );
        }
        return null;
    };

    const GradingSection = () => {
        if (caseAnswerData?.data?.feedback) {
            return (
                <Card styles={{ body: { backgroundColor: "#fff7e6" } }}>
                    <Score />
                    <Feedback />
                </Card>
            );
        }
        return null;
    };

    const [dictationRunning, setDictationRunning] = useState(false);

    const GetSpeech = () => {
        const SpeechRecognition =
            window.SpeechRecognition || window.webkitSpeechRecognition;

        const recognition = new SpeechRecognition();

        recognition.onstart = () => {
            setDictationRunning(true);
        };
        recognition.onspeechend = () => {
            recognition.stop();
        };
        recognition.onresult = (result) => {
            const newTranscript = result.results[0][0].transcript;
            const currentAnswer = form.getFieldValue("answer");
            form.setFieldsValue({
                answer: currentAnswer + " " + newTranscript,
            });
            form.submit();
            setDictationRunning(false);
        };

        recognition.start();
    };

    return (
        <div>
            {/* <h3>Question</h3> */}
            <Markdown>{question.question}</Markdown>
            <Divider />
            <Form {...formProps} requiredMark={false}>
                <Form.Item
                    label={
                        <>
                            <h3>Answer</h3>
                            <AutoSaveIndicator
                                {...autoSaveProps}
                                elements={{
                                    success: <></>,
                                }}
                            />
                        </>
                    }
                    name="answer"
                    rules={[{ required: true }]}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    {/* Text area input */}
                    <Input.TextArea
                        autoSize={{ minRows: 5, maxRows: 16 }}
                        placeholder="Enter your answer"
                    />
                </Form.Item>
            </Form>
            <Button onClick={GetSpeech} style={{ marginBottom: "1rem" }}>
                {dictationRunning ? "Stop Dictation" : "Start Dictation"}
            </Button>
            <GradingSection />
        </div>
    );
};

const FeedbackSummary = ({ caseAttemptId }: { caseAttemptId: number }) => {
    const { queryResult } = useShow({
        resource: "case-attempts",
        id: caseAttemptId,
    });

    const { data } = queryResult;

    return (
        <div>
            <h3>Total Score</h3>
            <p>{data?.data?.total_score}/100</p>
            <Markdown>{data?.data?.feedback_summary}</Markdown>
        </div>
    );
};

export const CaseAttemptShow = () => {
    const { case_id, attempt_id } = useParams<{
        case_id: string;
        attempt_id: string;
    }>();

    const { queryResult: caseAttemptQueryResult } = useShow({
        resource: "case-attempts",
        id: attempt_id,
    });

    const { data: caseAttemptData } = caseAttemptQueryResult;

    const { queryResult } = useShow({
        resource: "cases",
        id: case_id,
    });

    const { data, isLoading } = queryResult;

    const { t } = useTranslation();

    const record = data?.data;

    const [isAssistantVisible, setAssistantVisible] = useState(true);

    const copyQuizPublicLink = () => {
        const publicLink = `${window.location.origin}/case-public/show/${data?.data.uuid}`;
        navigator.clipboard.writeText(publicLink);
        notification.success({
            message: "Public link copied to clipboard",
            description: publicLink,
            duration: 2,
        });
    };

    const { mutate: mutateGradeAttempt } = useCreate();

    const onGradeAttempt = () => {
        mutateGradeAttempt(
            {
                resource: "case-attempt-grade",
                values: {
                    case_attempt_id: caseAttemptData?.data?.id,
                },
            },
            {
                onSuccess: () => {
                    notification.success({
                        message: "Grading submitted",
                        description:
                            "Please wait for the AI tutor to provide feedback",
                    });

                    // Delay the invalidate call by 2 seconds
                    const timeout = setTimeout(() => {
                        invalidate({
                            resource: "case-attempts",
                            invalidates: ["all"],
                        });
                    }, 2000); // 2-second delay

                    // Clear the timeout if needed (optional)
                    return () => clearTimeout(timeout);
                },
            }
        );
    };

    const invalidate = useInvalidate();

    useEffect(() => {
        if (caseAttemptData?.data?.is_grading_in_progress) {
            const interval = setInterval(() => {
                invalidate({
                    resource: "case-attempts",
                    invalidates: ["all"],
                });
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [caseAttemptData?.data?.is_graded, caseAttemptData?.data?.is_grading_in_progress]);

    if (isLoading || !record) {
        return null;
    }

    return (
        <div
            style={{
                height: "80vh",
            }}
        >
            <Show
                title={record.name}
                isLoading={isLoading}
                contentProps={{
                    style: {
                        backgroundColor: "#F5F5F5",
                        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0)",
                        margin: "-24px",
                        marginTop: "-16px",
                    },
                }}
                headerButtons={({ editButtonProps }) => {
                    return (
                        <>
                            <Button
                                size="middle"
                                onClick={copyQuizPublicLink}
                                icon={<CopyFilled />}
                            >
                                {t(
                                    "actions.copyPublicLink",
                                    "Copy Public Link"
                                )}
                            </Button>
                            <EditButton {...editButtonProps} />
                            <Button
                                type="primary"
                                onClick={() =>
                                    setAssistantVisible(!isAssistantVisible)
                                }
                                icon={<MessageOutlined />}
                            >
                                {t("assistant.toggleTutor", "Toggle Tutor")}
                            </Button>
                        </>
                    );
                }}
            >
                <PanelGroup direction="horizontal">
                    <Panel id="P-quiz" minSize={40} defaultSize={60}>
                        <Card>
                            <div
                                className="overflow-y-auto"
                                style={{ height: "70vh" }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        marginBottom: "16px",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                        }}
                                    >
                                        <h3>Problem Statement</h3>
                                        <div>
                                            <Tag
                                                color="blue"
                                                style={{
                                                    marginBottom: "0.1rem",
                                                    marginLeft: "1rem",
                                                }}
                                            >
                                                {record.category_label}
                                            </Tag>
                                        </div>
                                    </div>
                                    {!caseAttemptData?.data?.is_graded &&
                                    !caseAttemptData?.data?.is_grading_in_progress ? (
                                        <Button
                                            onClick={onGradeAttempt}
                                            type="primary"
                                        >
                                            {t(
                                                "actions.gradeAttempt",
                                                "Grade Attempt"
                                            )}
                                        </Button>
                                    ) : caseAttemptData?.data?.is_grading_in_progress ? (
                                        <Spin tip="Grading" size="large"></Spin>
                                    ) : null}
                                </div>
                                <Markdown className="mb-8">
                                    {record.description}
                                </Markdown>
                                <Tabs
                                    defaultActiveKey="1"
                                    size="middle"
                                    type="card"
                                    items={[
                                        ...(record?.questions?.map(
                                            (question: CaseQuestionType) => ({
                                                key: question.id,
                                                label: question.name,
                                                children: (
                                                    <CaseQuestion
                                                        question={question}
                                                        caseAttemptId={
                                                            caseAttemptData
                                                                ?.data?.id
                                                        }
                                                    />
                                                ),
                                            })
                                        ) || []),
                                        ...(caseAttemptData?.data?.is_graded
                                            ? [
                                                  {
                                                      key: "feedback-summary",
                                                      label: "Feedback Summary",

                                                      children: (
                                                          <FeedbackSummary
                                                              caseAttemptId={
                                                                  caseAttemptData
                                                                      ?.data?.id
                                                              }
                                                          />
                                                      ),
                                                  },
                                              ]
                                            : []),
                                    ]}
                                />
                            </div>
                        </Card>
                    </Panel>
                    {isAssistantVisible && (
                        <>
                            <PanelResizeHandle />
                            <Panel id="P-assistant" minSize={30}>
                                <Assistant
                                    assistantId={record.assistant}
                                    contentType={ContentType.CASE}
                                    contentId={record.id}
                                    height="75vh"
                                />
                            </Panel>
                        </>
                    )}
                </PanelGroup>
            </Show>
        </div>
    );
};
