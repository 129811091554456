import { Edit, useForm } from "@refinedev/antd";
import { CaseForm } from "./form";
import { CaseType } from "./types";

export const CaseEdit = () => {
    const { formProps, saveButtonProps, queryResult, formLoading } = useForm(
        {
            redirect: "edit"
        }
    );

    return (
        <Edit saveButtonProps={saveButtonProps} isLoading={formLoading}>
            <CaseForm
                formProps={formProps}
                data={queryResult?.data?.data as CaseType | undefined}
            />
        </Edit>
    );
};
