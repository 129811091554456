import { Form, FormProps, Input, Checkbox, Select } from "antd";
import {
    MultipleChoiceAnswerType,
    MultipleChoiceQuestionType,
    QuizType,
} from "./types";
import { MarkdownEditor } from "components/markdown-editor";
import { Button, Row, Col } from "antd";
import { DeleteButton, useForm, useSelect } from "@refinedev/antd";
import { Collapse } from "antd";
import {
    useApiUrl,
    useCreate,
    useCustomMutation,
    useInvalidate,
    useMany,
    useOne,
    useUpdate,
} from "@refinedev/core";
import { CopyOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";

import { FC, MouseEventHandler } from "react";

interface Entity {
    order: number;
    id: number;
    uuid: string;
}

interface UpDownOrderButtonsProps {
    resource: string;
    // eslint-disable-next-line @typescript-eslint/ban-types
    mutateUpdate: Function;
    entity: Entity;
    entities: Entity[];
    index: number;
}

const UpDownOrderButtons: FC<UpDownOrderButtonsProps> = ({
    resource,
    mutateUpdate,
    entity,
    entities,
    index,
}) => {
    const handleUpClick: MouseEventHandler<HTMLButtonElement> = () => {
        const currentOrder = entity.order;

        mutateUpdate({
            resource,
            id: entity.id,
            values: {
                order: entities[index - 1].order,
            },
            successNotification: () => {
                return {
                    type: "hidden",
                };
            },
        });

        mutateUpdate({
            resource,
            id: entities[index - 1].id,
            values: {
                order: currentOrder,
            },
            successNotification: () => {
                return {
                    type: "hidden",
                };
            },
        });
    };

    const handleDownClick: MouseEventHandler<HTMLButtonElement> = () => {
        const currentOrder = entity.order;

        mutateUpdate({
            resource,
            id: entity.id,
            values: {
                order: entities[index + 1].order,
            },
            successNotification: () => {
                return {
                    type: "hidden",
                };
            },
        });

        mutateUpdate({
            resource,
            id: entities[index + 1].id,
            values: {
                order: currentOrder,
            },
            successNotification: () => {
                return {
                    type: "hidden",
                };
            },
        });
    };

    return (
        <>
            <Button
                disabled={index === 0}
                onClick={handleUpClick}
                size="small"
                className="mr-2"
            >
                <UpOutlined />
            </Button>
            <Button
                disabled={index === entities.length - 1}
                onClick={handleDownClick}
                size="small"
                className="mr-2"
            >
                <DownOutlined />
            </Button>
        </>
    );
};

export default UpDownOrderButtons;

export const CloneInstanceButton = ({
    resource,
    id,
    hideText = false,
    className = "",
    onClick = () => {},
}: {
    resource: string;
    id: string;
    hideText: boolean;
    className: string;
    // eslint-disable-next-line @typescript-eslint/ban-types
    onClick: Function;
}) => {
    const apiUrl = useApiUrl();
    const { mutate } = useCustomMutation();

    const cloneInstance = () => {
        mutate({
            url: `${apiUrl}/${resource}/${id}/clone/`,
            method: "post",
        });
    };

    return (
        <Button
            icon={<CopyOutlined />}
            size="small"
            onClick={() => {
                cloneInstance();
                if (onClick) {
                    onClick();
                }
            }}
            className={className}
        >
            {!hideText && "Clone"}
        </Button>
    );
};

const MultipleChoiceAnswerForm = ({ answerId }: { answerId: number }) => {
    const { queryResult, formProps } = useForm({
        action: "edit",
        resource: "multiple-choice-answers",
        id: answerId,
        autoSave: {
            enabled: true,
            debounce: 1000,
        },
    });

    const answer = queryResult.data?.data;

    const invalidate = useInvalidate();

    return (
        <>
            <Form {...formProps}>
                <Row gutter={16} justify="space-between">
                    <Col span={14}>
                        <Form.Item
                            label="Answer"
                            name="answer"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input the answer!",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col
                        span={4}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                        <Form.Item
                            name="is_correct"
                            label="Is Correct"
                            valuePropName="checked"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "Please input if the answer is correct!",
                                },
                            ]}
                        >
                            <Checkbox />
                        </Form.Item>
                    </Col>
                    <Col
                        span={4}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                        <CloneInstanceButton
                            hideText
                            resource="multiple-choice-answers"
                            id={answer?.id}
                            className="mr-2"
                            onClick={() => {
                                invalidate({
                                    resource: "multiple-choice-questions",
                                    invalidates: ["resourceAll"],
                                });
                            }}
                        />
                        <DeleteButton
                            hideText
                            size="small"
                            resource="multiple-choice-answers"
                            recordItemId={answer?.id}
                            invalidates={["resourceAll"]}
                            onSuccess={() => {
                                invalidate({
                                    resource: "multiple-choice-questions",
                                    invalidates: ["resourceAll"],
                                });
                            }}
                        />
                    </Col>
                </Row>
            </Form>
        </>
    );
};

const MultipleChoiceQuestionForm = ({ questionId }: { questionId: number }) => {
    const invalidate = useInvalidate();

    const { queryResult, formProps } = useForm({
        action: "edit",
        resource: "multiple-choice-questions",
        id: questionId,
        autoSave: {
            enabled: true,
            debounce: 1000,
        },
        onMutationSuccess: () => {
            invalidate({
                resource: "quizzes",
                invalidates: ["all"],
            });
        },
    });

    const question = queryResult.data?.data;

    const { mutate } = useCreate();

    const { data: answersQuery } = useMany({
        resource: "multiple-choice-answers",
        ids:
            question?.answers?.map(
                (answer: MultipleChoiceAnswerType) => answer.id
            ) || [],
    });

    if (queryResult.isLoading) {
        return <p>Loading...</p>;
    }

    return (
        <>
            <Form {...formProps} layout="vertical">
                <Form.Item
                    label="Title"
                    name="title"
                    rules={[
                        {
                            required: true,
                            message: "Please input the title of the question!",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Content"
                    name="content"
                    rules={[
                        {
                            required: true,
                            message:
                                "Please input the description of the question!",
                        },
                    ]}
                >
                    <MarkdownEditor preview="edit" />
                </Form.Item>
                <Form.Item
                    label="Explanation"
                    name="explanation"
                    rules={[
                        {
                            required: true,
                            message:
                                "Please input the explanation of the correct solution!",
                        },
                    ]}
                >
                    <MarkdownEditor preview="edit" />
                </Form.Item>
            </Form>
            <h4>Answers</h4>
            {answersQuery?.data?.map((answer: MultipleChoiceAnswerType) => (
                <MultipleChoiceAnswerForm
                    answerId={answer.id}
                    key={answer.id}
                />
            ))}
            <Row
                justify="center"
                align="middle"
                gutter={[16, 8]}
                className="mt-8"
            >
                <Col
                    xs={24}
                    sm={24}
                    style={{ display: "flex", justifyContent: "center" }}
                >
                    <Button
                        type="primary"
                        onClick={() => {
                            mutate({
                                resource: "multiple-choice-answers",
                                values: {
                                    answer: "",
                                    question: question?.id,
                                },
                                successNotification: () => {
                                    return {
                                        type: "hidden",
                                    };
                                },
                            });
                            invalidate({
                                resource: "multiple-choice-questions",
                                invalidates: ["resourceAll"],
                            });
                        }}
                    >
                        Add Answer
                    </Button>
                </Col>
            </Row>
        </>
    );
};

const QuestionsTable = ({
    questions,
    invalidate,
    mutateUpdate,
}: {
    questions: MultipleChoiceQuestionType[];
    // eslint-disable-next-line @typescript-eslint/ban-types
    invalidate: Function;
    // eslint-disable-next-line @typescript-eslint/ban-types
    mutateUpdate: Function;
}) => {
    return (
        <Collapse accordion>
            {questions.map((question: MultipleChoiceQuestionType, index) => (
                <Collapse.Panel
                    collapsible="icon"
                    header={question.title}
                    key={question.id}
                    extra={
                        <>
                            <UpDownOrderButtons
                                resource="multiple-choice-questions"
                                mutateUpdate={mutateUpdate}
                                entity={question}
                                entities={questions}
                                index={index}
                            />
                            <CloneInstanceButton
                                hideText
                                resource="multiple-choice-questions"
                                id={question.id as unknown as string}
                                className="mr-2"
                                onClick={() => {
                                    invalidate({
                                        resource: "quizzes",
                                        invalidates: ["resourceAll"],
                                    });
                                }}
                            />
                            <DeleteButton
                                hideText
                                size="small"
                                resource="multiple-choice-questions"
                                recordItemId={question.id}
                                invalidates={["resourceAll"]}
                                onSuccess={() => {
                                    invalidate({
                                        resource: "quizzes",
                                        invalidates: ["resourceAll"],
                                    });
                                }}
                            />
                        </>
                    }
                >
                    <MultipleChoiceQuestionForm questionId={question.id} />
                </Collapse.Panel>
            ))}
        </Collapse>
    );
};

export const QuizForm = ({
    formProps,
    data,
}: {
    formProps: FormProps;
    data: QuizType | undefined;
}) => {
    const defaultData = {};

    const { data: quizQuery } = useOne({
        resource: "quizzes",
        id: data?.id,
        queryOptions: { enabled: !!data?.id },
    });

    const { data: questionsQuery } = useMany({
        resource: "multiple-choice-questions",
        ids: quizQuery?.data?.question_ids || [],
    });

    const { mutate } = useCreate();
    const { mutate: mutateUpdate } = useUpdate();
    const invalidate = useInvalidate();

    const { selectProps: assistantSelectProps } = useSelect({
        resource: "assistants",
        optionLabel(item) {
            return item?.name;
        },
    });

    return (
        <>
            <Form
                {...formProps}
                layout="vertical"
                initialValues={data || defaultData}
            >
                <Form.Item
                    label={"Name"}
                    name={["name"]}
                    rules={[{ required: true }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={"Description"}
                    name={["description"]}
                    rules={[{ required: true }]}
                >
                    <MarkdownEditor preview="edit" />
                </Form.Item>
                <Form.Item
                    label={"Assistant"}
                    name={["assistant"]}
                    rules={[{ required: true }]}
                >
                    <Select {...assistantSelectProps} />
                </Form.Item>
            </Form>
            {!!data && (
                <>
                    <div>
                        <h2>Questions</h2>
                        <QuestionsTable
                            questions={
                                questionsQuery?.data?.sort(
                                    (
                                        a: MultipleChoiceQuestionType,
                                        b: MultipleChoiceQuestionType
                                    ) => a.order - b.order
                                ) || []
                            }
                            invalidate={invalidate}
                            mutateUpdate={mutateUpdate}
                        />
                        <Row
                            justify="center"
                            align="middle"
                            gutter={[16, 8]}
                            className="mt-8"
                        >
                            <Col
                                xs={24}
                                sm={12}
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        mutate({
                                            resource:
                                                "multiple-choice-questions",
                                            values: {
                                                quiz: data?.id,
                                            },
                                            successNotification: () => {
                                                return {
                                                    type: "hidden",
                                                };
                                            },
                                        });
                                        invalidate({
                                            resource: "quizzes",
                                            invalidates: ["resourceAll"],
                                        });
                                    }}
                                >
                                    Add Multiple Choice Question
                                </Button>
                            </Col>
                            <Col
                                xs={24}
                                sm={12}
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <Button
                                    type="primary"
                                    disabled
                                    onClick={() => {}}
                                >
                                    Add Open Question
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </>
            )}
            {!data && (
                <h4>Create the Quiz first to be able to add questions ...</h4>
            )}
        </>
    );
};
