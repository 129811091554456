import { CopyFilled } from "@ant-design/icons";
import { EditButton, Show } from "@refinedev/antd";
import { useShow } from "@refinedev/core";
import { Button, notification } from "antd";
import { Assistant, ContentType } from "components/assistant";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCurrentTenant } from "tenants";
import { Feature } from "utilities/availableFeatures";

export const AssistantShow = () => {
    const { queryResult } = useShow({});
    const { data, isLoading } = queryResult;

    const record = data?.data;

    const { id: assistantId } = useParams<{
        id: string;
    }>();

    const copyPublicAssistantLink = () => {
        const publicLink = `${window.location.origin}/assistant-public/show/${data?.data.uuid}`;
        navigator.clipboard.writeText(publicLink);
        notification.success({
            message: "Public link copied to clipboard",
            description: publicLink,
            duration: 2,
        });
    };
    const { t } = useTranslation();

    const { features: tenantFeatures } = useCurrentTenant();

    if (!assistantId) {
        return null;
    }

    return (
        <div style={{ height: "80vh" }}>
            <Show
                breadcrumb
                title={record?.name}
                isLoading={isLoading}
                contentProps={{
                    style: {
                        backgroundColor: "#F5F5F5",
                        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0)",
                        margin: "-24px",
                        marginTop: "-16px",
                        width: "100%",
                    },
                }}
                headerButtons={({ editButtonProps }) => {
                    return (
                        <>
                            {tenantFeatures.includes(
                                Feature.PublicContentLinks
                            ) && (
                                <Button
                                    size="middle"
                                    onClick={copyPublicAssistantLink}
                                    icon={<CopyFilled />}
                                >
                                    {t(
                                        "actions.copyPublicLink",
                                        "Copy Public Link"
                                    )}
                                </Button>
                            )}
                            <EditButton {...editButtonProps} />
                        </>
                    );
                }}
            >
                <div className="items-center mx-auto max-w-[800px] w-full">
                    <Assistant
                        assistantId={assistantId as unknown as number}
                        contentType={ContentType.ASSISTANT}
                        contentId={assistantId as unknown as number}
                    />
                </div>
            </Show>
        </div>
    );
};
