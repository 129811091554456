import { useState, useRef, useEffect } from "react";
import { Form, Input, TreeSelect, Select, Upload, Collapse } from "antd";
import { FormProps } from "antd/lib";
import { useTranslation } from "react-i18next";
import { YooptaContentValue } from "@yoopta/editor";
import StarterKit from "@yoopta/starter-kit";
import styles from "./ContentPage.module.css";
import { ContentPageType } from "./types";
import { TreeSelectProps } from "antd";
import { getValueFromEvent } from "@refinedev/antd";

import { useList } from "@refinedev/core";
import { AssistantType } from "pages/assistants/types";

export const useTreeSelect = (props: {
    resource: string;
    currentId?: number;
}) => {
    const { data, isLoading } = useList({
        resource: props.resource,
    });

    const convertToTreeData = (
        items: ContentPageType[],
        currentId?: number
    ): TreeSelectProps["treeData"] => {
        return items.map((item) => ({
            title: item.title,
            value: item.id,
            disabled: currentId
                ? isDescendantOf(item, currentId as number)
                : false,
            children: item.children
                ? convertToTreeData(item.children, currentId)
                : undefined,
        }));
    };

    const isDescendantOf = (
        item: ContentPageType,
        ancestorId: number
    ): boolean => {
        if (item.id === ancestorId) return true;
        const parent = data?.data.find(
            (p: ContentPageType) => p.id === item.parent
        );
        if (!parent) {
            return false;
        }
        if (item.parent)
            return (
                item.parent === ancestorId || isDescendantOf(parent, ancestorId)
            );
        return false;
    };

    return {
        treeData: convertToTreeData(
            data?.data.filter((item: ContentPageType) => !item.parent) || [],
            props.currentId
        ),
        loading: isLoading,
    };
};

export const ContentPageForm = ({
    formProps,
    initialValues,
    isEditing = false,
}: {
    formProps: FormProps;
    initialValues?: ContentPageType;
    isEditing?: boolean;
}) => {
    const { t } = useTranslation();
    const [editorContent, setEditorContent] = useState<YooptaContentValue>(
        initialValues?.content || {}
    );
    const selectionBoxRoot = useRef<HTMLDivElement | null>(null);

    const { data: assistantsData, isLoading: assistantsLoading } = useList({
        resource: "assistants",
    });

    const [thumbnailToDelete, setThumbnailToDelete] = useState(false);
    const [iconToDelete, setIconToDelete] = useState(false);

    useEffect(() => {
        if (initialValues?.content) {
            setEditorContent(initialValues.content);
        }
    }, [initialValues]);

    const { treeData, loading } = useTreeSelect({
        resource: "content-pages",
        currentId: initialValues?.id,
    });

    return (
        <Form
            {...formProps}
            layout="vertical"
            initialValues={initialValues}
            onFinish={(values) => {
                const { thumbnail, icon, ...otherValues } = values;

                const formData = new FormData();

                Object.keys(otherValues).forEach((key) => {
                    formData.append(key, otherValues[key]);
                });

                formData.append("content", JSON.stringify(editorContent));

                if (thumbnail && thumbnail[0] && thumbnail[0].originFileObj) {
                    formData.append("thumbnail", thumbnail[0].originFileObj);
                } else if (thumbnailToDelete) {
                    formData.append("thumbnail", "");
                }

                if (icon && icon[0] && icon[0].originFileObj) {
                    formData.append("icon", icon[0].originFileObj);
                } else if (iconToDelete) {
                    formData.append("icon", "");
                }

                return formProps.onFinish?.(formData);
            }}
        >
            <Form.Item
                label={t("content-pages.fields.title", "Title")}
                name="title"
                rules={[{ required: true }]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label={t("content-pages.fields.content", "Content")}
                name="content"
            >
                <div
                    ref={selectionBoxRoot}
                    className={styles.editorContainer}
                    style={{ paddingLeft: "52px" }}
                >
                    <StarterKit
                        style={{ margin: "16px" }}
                        value={editorContent}
                        onChange={(data) => setEditorContent(data)}
                        selectionBoxRoot={selectionBoxRoot}
                        placeholder={t(
                            "content-pages.placeholders.content",
                            "Start typing here..."
                        )}
                        // ... media upload handlers
                    />
                </div>
            </Form.Item>

            {!isEditing && (
                <Form.Item
                    label={t("content-pages.fields.assistant", "Assistant")}
                    name="assistant"
                    rules={[
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!getFieldValue("parent") && !value) {
                                    return Promise.reject(
                                        "Top-level pages must have an assistant assigned 1"
                                    );
                                }
                                return Promise.resolve();
                            },
                        }),
                    ]}
                >
                    <Select
                        loading={assistantsLoading}
                        allowClear
                        placeholder={t(
                            "content-pages.fields.assistant_placeholder",
                            "Select an assistant (Optional for child pages)"
                        )}
                    >
                        {assistantsData?.data.map(
                            (assistant: AssistantType) => (
                                <Select.Option
                                    key={assistant.id}
                                    value={assistant.id}
                                >
                                    {assistant.name}
                                </Select.Option>
                            )
                        )}
                    </Select>
                    {!initialValues?.assistant &&
                        initialValues?.assistant_id &&
                        initialValues?.assistant_label && (
                            <div className="text-sm text-gray-500 mt-1">
                                {t(
                                    "content-pages.fields.inherited_assistant",
                                    "Inherited assistant"
                                )}
                                : {initialValues.assistant_label}
                            </div>
                        )}
                </Form.Item>
            )}

            <Collapse>
                <Collapse.Panel
                    header={t("content-pages.fields.advanced", "Advanced")}
                    key="1"
                >
                    {isEditing && (
                        <Form.Item
                            label={t(
                                "content-pages.fields.assistant",
                                "Assistant"
                            )}
                            name="assistant"
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (
                                            !getFieldValue("parent") &&
                                            !value
                                        ) {
                                            return Promise.reject(
                                                "Top-level pages must have an assistant assigned 2"
                                            );
                                        }
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                        >
                            <Select
                                loading={assistantsLoading}
                                allowClear
                                placeholder={t(
                                    "content-pages.fields.assistant_placeholder",
                                    "Select an assistant (Optional for child pages)"
                                )}
                            >
                                {assistantsData?.data.map(
                                    (assistant: AssistantType) => (
                                        <Select.Option
                                            key={assistant.id}
                                            value={assistant.id}
                                        >
                                            {assistant.name}
                                        </Select.Option>
                                    )
                                )}
                            </Select>
                            {!initialValues?.assistant &&
                                initialValues?.assistant_id &&
                                initialValues?.assistant_label && (
                                    <div className="text-sm text-gray-500 mt-1">
                                        {t(
                                            "content-pages.fields.inherited_assistant",
                                            "Inherited assistant"
                                        )}
                                        : {initialValues.assistant_label}
                                    </div>
                                )}
                        </Form.Item>
                    )}
                    {isEditing && (
                        <Form.Item
                            label={t(
                                "content-pages.fields.parent",
                                "Parent Page"
                            )}
                            name="parent"
                        >
                            <TreeSelect
                                treeData={treeData}
                                loading={loading}
                                treeDefaultExpandAll
                                allowClear
                                placeholder={t(
                                    "content-pages.fields.parent_placeholder",
                                    "Select a parent page (Optional)"
                                )}
                            />
                        </Form.Item>
                    )}
                </Collapse.Panel>

                <Collapse.Panel
                    header={t("content-pages.fields.design", "Design")}
                    key="2"
                >
                    <Form.Item
                        label={t("content-pages.fields.thumbnail", "Thumbnail")}
                        name="thumbnail"
                        valuePropName="file"
                        getValueFromEvent={getValueFromEvent}
                    >
                        <Upload.Dragger
                            accept="image/*"
                            listType="picture"
                            maxCount={1}
                            beforeUpload={() => false}
                            defaultFileList={
                                initialValues?.thumbnail && !thumbnailToDelete
                                    ? [
                                          {
                                              uid: "-1",
                                              name: initialValues?.thumbnail_label || "",
                                              status: "done",
                                              url: initialValues?.thumbnail || "",
                                          },
                                      ]
                                    : []
                            }
                            onRemove={() => setThumbnailToDelete(true)}
                        >
                            <p className="ant-upload-text">
                                {t(
                                    "content-pages.fields.upload_thumbnail",
                                    "Drag & drop thumbnail here or click to upload"
                                )}
                            </p>
                        </Upload.Dragger>
                    </Form.Item>

                    <Form.Item
                        label={t("content-pages.fields.icon", "Icon")}
                        name="icon"
                        valuePropName="file"
                        getValueFromEvent={getValueFromEvent}
                    >
                        <Upload.Dragger
                            accept="image/*"
                            listType="picture"
                            maxCount={1}
                            beforeUpload={() => false}
                            defaultFileList={
                                initialValues?.icon && !iconToDelete
                                    ? [
                                          {
                                              uid: "-1",
                                              name: initialValues?.icon_label || "",
                                              status: "done",
                                              url: initialValues?.icon || "",
                                          },
                                      ]
                                    : []
                            }
                            onRemove={() => setIconToDelete(true)}
                        >
                            <p className="ant-upload-text">
                                {t(
                                    "content-pages.fields.upload_icon",
                                    "Drag & drop icon here or click to upload"
                                )}
                            </p>
                        </Upload.Dragger>
                    </Form.Item>
                </Collapse.Panel>
            </Collapse>
        </Form>
    );
};
