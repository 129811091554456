import { List } from "@refinedev/antd";
import { VideoThumbnailList } from "components/content-video/thumbnailList";
import { useTable } from "@refinedev/antd";
import { useGetIdentity } from "@refinedev/core";
import { IIdentity } from "../../authProvider";

export const ContentVideoList = () => {
    const { tableQueryResult } = useTable({
        syncWithLocation: true,
        resource: "content-videos",
    });
    const { data: identity } = useGetIdentity<IIdentity>();

    if (tableQueryResult.isLoading || !identity) {
        return <div>Loading...</div>;
    }

    return (
        <List canCreate={identity?.is_superuser}>
            <VideoThumbnailList contentVideos={tableQueryResult?.data?.data} />
        </List>
    );
};
