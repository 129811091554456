import React from "react";
import { useList, useGetIdentity } from "@refinedev/core";
import { Table, Card, Row, Col, Space } from "antd";
import {
    DeleteButton,
    EditButton,
    ShowButton,
    useTable,
} from "@refinedev/antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ContentList } from "components/layout/content-list";
import { IIdentity } from "../../authProvider";
import { AssistantType } from "./types";

const AssistantCardList: React.FC = () => {
    const { data, isLoading } = useList<AssistantType>({
        resource: "assistants",
    });

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="p-4">
            <Row gutter={[16, 16]}>
                {data?.data.map((assistant: AssistantType) => (
                    <Col key={assistant.id} xs={24} sm={12} md={8} lg={8} xl={8}>
                        <Link to={`/assistants/show/${assistant.id}`}>
                            <Card
                                hoverable
                                cover={
                                    <img
                                        alt={assistant.name}
                                        src={
                                            assistant.icon ||
                                            `https://picsum.photos/seed/${assistant.id}/300/300`
                                        }
                                        className="h-40 w-40 object-cover"
                                    />
                                }
                                className="h-full"
                            >
                                <Card.Meta title={assistant.name} />
                            </Card>
                        </Link>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

const AssistantTableList: React.FC = () => {
    const { tableProps, tableQueryResult } = useTable<AssistantType>({
        syncWithLocation: true,
        resource: "assistants",
    });
    const { data: identity } = useGetIdentity<IIdentity>();
    const { t } = useTranslation();

    if (tableQueryResult.isLoading || !identity) {
        return <div>Loading...</div>;
    }

    const columns = [
        { title: t("fields.id", "ID"), dataIndex: "id", key: "id" },
        { title: t("fields.title", "Title"), dataIndex: "title", key: "title" },
        {
            title: t("fields.created_at", "Created At"),
            dataIndex: "created_at",
            key: "created_at",
            render: (value: string) => new Date(value).toLocaleString(),
        },
        {
            title: t("fields.updated_at", "Updated At"),
            dataIndex: "updated_at",
            key: "updated_at",
            render: (value: string) => new Date(value).toLocaleString(),
        },
        {
            title: t("tableHeaders.actions"),
            key: "actions",
            render: (_: React.ReactNode, record: AssistantType) => (
                <Space align="end">
                    <EditButton
                        hideText
                        size="small"
                        recordItemId={record.id}
                    />
                    <ShowButton
                        hideText
                        size="small"
                        recordItemId={record.id}
                    />
                    <DeleteButton
                        hideText
                        size="small"
                        recordItemId={record.id}
                    />
                </Space>
            ),
        },
    ];

    return <Table {...tableProps} columns={columns} rowKey="id" />;
};

export const AssistantList: React.FC = () => {
    return (
        <ContentList
            cardComponent={AssistantCardList}
            tableComponent={AssistantTableList}
            cookieKey="assistants-view"
        />
    );
};
