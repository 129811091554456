import { Form, Input, Select, Upload } from "antd";
import { ContentVideoType } from "./types";
import { getValueFromEvent, useSelect } from "@refinedev/antd";
import { FormProps } from "antd/lib";

export const ContentVideoForm = ({
    data,
    formProps,
}: {
    data?: ContentVideoType;
    formProps: FormProps;
}) => {
    const { selectProps: assistantSelectProps } = useSelect({
        resource: "assistants",
        optionLabel(item) {
            return item?.name;
        },
    });

    if (!formProps) {
        return null;
    }

    return (
        <Form
            {...formProps}
            layout="vertical"
            initialValues={data}
            onFinish={async (values) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const { videos, thumbnails, ...otherValues } = values as any;
                const video =
                    (videos && videos[0] && videos[0].originFileObj) || null;
                if (video) {
                    otherValues.video = video;
                }
                const thumbnail =
                    (thumbnails &&
                        thumbnails[0] &&
                        thumbnails[0].originFileObj) ||
                    null;
                if (thumbnail) {
                    otherValues.thumbnail = thumbnail;
                }
                return formProps.onFinish?.({
                    ...otherValues,
                });
            }}
        >
            <Form.Item
                label={"Title"}
                name={["title"]}
                rules={[{ required: true }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={"Assistant"}
                name={["assistant"]}
                rules={[{ required: true }]}
            >
                <Select {...assistantSelectProps} />
            </Form.Item>
            <Form.Item label="Video">
                <Form.Item
                    name="videos"
                    valuePropName="file"
                    getValueFromEvent={getValueFromEvent}
                    noStyle
                >
                    <Upload.Dragger
                        listType="picture"
                        maxCount={1}
                        defaultFileList={[
                            {
                                uid: "-1",
                                name: "video.pdf",
                                status: "done",
                                url: data?.video || "",
                            },
                        ]}
                        beforeUpload={() => false}
                    >
                        <p className="ant-upload-text">
                            Drag & drop a video in this area
                        </p>
                    </Upload.Dragger>
                </Form.Item>
                <Form.Item label="Thumbnail">
                    <Form.Item
                        name="thumbnails"
                        valuePropName="file"
                        getValueFromEvent={getValueFromEvent}
                        noStyle
                    >
                        <Upload.Dragger
                            listType="picture"
                            maxCount={1}
                            defaultFileList={[
                                {
                                    uid: "-1",
                                    name: "thumbnail.png",
                                    status: "done",
                                    url: data?.thumbnail || "",
                                },
                            ]}
                            beforeUpload={() => false}
                        >
                            <p className="ant-upload-text">
                                Drag & drop a thumbnail in this area
                            </p>
                        </Upload.Dragger>
                    </Form.Item>
                </Form.Item>
            </Form.Item>
        </Form>
    );
};
