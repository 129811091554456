import { Create, useForm } from "@refinedev/antd";
import { QuizForm } from "./form";

export const QuizCreate = () => {
    const { formProps, saveButtonProps } = useForm({
        redirect: "edit"
    });

    return (
        <Create saveButtonProps={saveButtonProps}>
            <QuizForm formProps={formProps} data={undefined} />
        </Create>
    );
};
