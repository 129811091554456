import React from "react";
import { Form, Select, FormProps } from "antd";
import { useSelect } from "@refinedev/antd";
import { useTranslate } from "@refinedev/core";

interface EvaluationFormProps {
  formProps: FormProps;
}

export const EvaluationForm: React.FC<EvaluationFormProps> = ({ formProps }) => {
  const t = useTranslate();

  const { selectProps: questionSetSelectProps } = useSelect({
    resource: "evaluation-question-sets",
    optionLabel: "name",
    optionValue: "id",
  });

  const { selectProps: assistantSelectProps } = useSelect({
    resource: "assistants",
    optionLabel: "name",
    optionValue: "id",
  });

  const { selectProps: documentSelectProps } = useSelect({
    resource: "content-documents",
    optionLabel: "title",
    optionValue: "id",
  });

  return (
    <Form {...formProps} layout="vertical">
      <Form.Item
        label={t("evaluations.fields.questionSet")}
        name="question_set"
        rules={[{ required: true }]}
      >
        <Select {...questionSetSelectProps} />
      </Form.Item>
      <Form.Item
        label={t("evaluations.fields.assistant")}
        name="assistant"
        rules={[{ required: true }]}
      >
        <Select {...assistantSelectProps} />
      </Form.Item>
      <Form.Item
        label={t("evaluations.fields.document")}
        name="document"
        rules={[{ required: true }]}
      >
        <Select {...documentSelectProps} />
      </Form.Item>
    </Form>
  );
};

export default EvaluationForm;
