import { Edit, useForm } from "@refinedev/antd";
import { QuizForm } from "./form";
import { QuizType } from "./types";

export const QuizEdit = () => {
    const { formProps, saveButtonProps, queryResult, formLoading } = useForm(
        {
            redirect: "edit"
        }
    );

    return (
        <Edit saveButtonProps={saveButtonProps} isLoading={formLoading}>
            <QuizForm
                formProps={formProps}
                data={queryResult?.data?.data as QuizType | undefined}
            />
        </Edit>
    );
};
