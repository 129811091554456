import { ChatMessage, ProChat, ProChatInstance } from "@ant-design/pro-chat";
import {
    useApiUrl,
    useCreate,
    useInvalidate,
    useList,
    useOne,
} from "@refinedev/core";
import "./style.css";
import { useEffect, useMemo, useRef, useState } from "react";
import { ISuggestion } from "types";
import { Locale } from "@ant-design/pro-chat/es/locale";
import { AssistantType } from "pages/assistants/types";
import { TOKEN_KEY } from "../../authProvider";
import { Select } from "antd/lib";
import { useSelect } from "@refinedev/antd";
import { Button, Col, Row, Space } from "antd";
import {
    PlusOutlined,
    CloseOutlined,
} from "@ant-design/icons";
import { SuggestionComponent } from "./suggestions";
import { useTranslation } from "react-i18next";

const actionsRender = () => {
    return <div></div>;
};

export enum ContentType {
    DOCUMENT = "document",
    QUIZ = "quiz",
    VIDEO = "video",
    ASSISTANT = "assistant",
    CASE = "case",
    PAGE = "page",
}

const setChatIdInSessionStorage = (
    isPublic: boolean,
    contentType: ContentType,
    contentId: number,
    chatId?: number
) => {
    const chatIdKey = isPublic ? "publicChatId" : "chatId";
    let chatIds = JSON.parse(sessionStorage.getItem(chatIdKey) || "{}");

    // Ensure chatIds is an object
    if (typeof chatIds !== "object" || chatIds === null) {
        chatIds = {};
    }

    // Ensure chatIds[contentType] is an object
    if (
        typeof chatIds[contentType] !== "object" ||
        chatIds[contentType] === null
    ) {
        chatIds[contentType] = {};
    }

    if (chatId !== undefined) {
        chatIds[contentType][contentId] = chatId;
    } else {
        delete chatIds[contentType][contentId];
    }

    sessionStorage.setItem(chatIdKey, JSON.stringify(chatIds));
};

const getChatIdFromSessionStorage = (
    isPublic: boolean,
    contentType: ContentType,
    contentId: number
) => {
    const chatIdKey = isPublic ? "publicChatId" : "chatId";
    const chatIds = JSON.parse(sessionStorage.getItem(chatIdKey) || "{}");

    // Ensure chatIds is an object
    if (typeof chatIds !== "object" || chatIds === null) {
        return "";
    }

    // Ensure chatIds[contentType] is an object
    if (
        typeof chatIds[contentType] !== "object" ||
        chatIds[contentType] === null
    ) {
        return "";
    }

    return chatIds[contentType][contentId] || "";
};

const sendMessageToServer = async (
    apiUrl: string,
    assistantId: number = 0,
    assistantUuid: string | null = null,
    message: ChatMessage,
    isPublic = false,
    contentType: ContentType,
    contentId?: number
) => {
    const meta =
        typeof message.content === "string"
            ? message.content.match(/<META>(.*)<\/META>/)
            : null;
    let action_type = null;
    let questionUuid = null;
    let answerUuid = null;
    if (meta) {
        const data = JSON.parse(meta[1]);
        action_type = data.actionType;
        questionUuid = data.questionUuid;
        answerUuid = data.answerUuid;
    }
    const data: {
        [key: string]: string | number | React.ReactNode | null;
        assistant_id: number;
        assistant_uuid: string | null;
        message: React.ReactNode;
        chat_message_history_id: number;
        action_type: "explain" | "hint" | null;
        question_uuid: string | null;
        answer_uuid: string | null;
        content_type: ContentType;
        content_id?: number | null;
    } = {
        assistant_id: assistantId,
        assistant_uuid: assistantUuid,
        message: message.content,
        chat_message_history_id: Number(
            getChatIdFromSessionStorage(isPublic, contentType, contentId || 0)
        ),
        action_type: action_type,
        question_uuid: questionUuid,
        answer_uuid: answerUuid,
        content_type: contentType,
        content_id: contentId,
    };

    // throw away the null and undefined values
    Object.keys(data).forEach((key) => !data[key] && delete data[key]);
    const url = isPublic
        ? `${apiUrl}/public/assistant-stream/`
        : `${apiUrl}/assistant-stream/`;
    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
        },
        body: JSON.stringify(data),
    });
    const reader = response?.body?.getReader();
    const stream = new ReadableStream({
        start(controller) {
            function pump(): Promise<void | undefined> | undefined {
                return reader?.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;
                    }
                    controller.enqueue(value);
                    return pump();
                });
            }
            return pump();
        },
    });

    return new Response(stream);
};

interface IChatMessageHistory {
    id: number;
    name: string;
}

const SourceButtons = ({
    sources,
    jumpToPage,
    showPages = true,
    setAssistantVisible,
    isMobile,
}: {
    sources: { page: number; description: string }[];
    jumpToPage?: (page: number) => void;
    showPages?: boolean;
    setAssistantVisible?: (visible: boolean) => void;
    isMobile?: boolean;
}) => {
    if (!sources || sources.length === 0) return null;

    const { t } = useTranslation();

    return (
        <div className="mt-2">
            <span className="text-sm font-semibold mr-2">
                {t("assistant.sources", "Sources")}:
            </span>
            <Space wrap>
                {sources.map((source, index) => (
                    // <Tooltip key={index} title={source.description} placement="top">
                    <Button
                        size="small"
                        onClick={() => {
                            jumpToPage && jumpToPage(source.page - 1);
                            if (isMobile) {
                                setAssistantVisible?.(false);
                            }
                        }}
                        className="px-2 py-0 text-xs"
                    >
                        {showPages ? `P${source.page}` : index + 1}
                    </Button>
                    // </Tooltip>
                ))}
            </Space>
        </div>
    );
};

export const Assistant = ({
    assistantId,
    assistantUuid,
    onEvent,
    isPublic = false,
    contentType,
    contentId,
    setAssistantVisible,
    enableHideButton = false,
    jumpToPage,
    isMobile = false,
}: {
    assistantId: number;
    assistantUuid?: string;
    onEvent?: {
        type: "explain" | "hint";
        payload: {
            is_correct?: boolean;
            question?: string;
            questionUuid?: string;
            questionTitle?: string;
            answer?: string;
            answerUuid?: string;
        };
    } | null;
    isPublic?: boolean;
    contentType: ContentType;
    contentId: number;
    setAssistantVisible?: (visible: boolean) => void;
    enableHideButton?: boolean;
    jumpToPage?: (page: number) => void;
    isMobile?: boolean;
}) => {
    const apiUrl = useApiUrl();

    const [selectedValue, setSelectedValue] = useState<{
        name: string;
        value: string | number;
    }>({
        name: "New Chat",
        value: "New Chat",
    });

    const { data: assistantData, isLoading: isAssistantLoading } =
        useOne<AssistantType>({
            resource: isPublic ? "public/assistants" : "assistants",
            id: isPublic ? assistantUuid : assistantId,
        });

    const chatRef = useRef<ProChatInstance>();
    const [suggestions, setSuggestions] = useState<ISuggestion[]>([]);

    useEffect(() => {
        if (onEvent) {
            setSuggestions([]);
            if (onEvent.type === "explain" && onEvent.payload.question) {
                chatRef.current?.sendMessage(
                    `<META>{"actionType": "explain", "questionUuid": "${onEvent.payload?.questionUuid}", "answerUuid": "${onEvent.payload?.answerUuid}", "questionTitle": "${onEvent.payload?.questionTitle}"}</META>` // `Fully explain the following problem: ${onEvent.payload.question}. My answer was ${onEvent.payload.answer}`
                );
                chatRef.current?.pushChat({
                    content: "Explain " + onEvent.payload?.questionTitle,
                    role: "user",
                });
            } else if (onEvent.type === "hint" && onEvent.payload.question) {
                chatRef.current?.sendMessage(
                    `<META>{"actionType": "hint", "questionUuid": "${onEvent.payload?.questionUuid}", "questionTitle": "${onEvent.payload?.questionTitle}"}</META>` // `Fully explain the following problem: ${onEvent.payload.question}. My answer was ${onEvent.payload.answer}`
                );
                chatRef.current?.pushChat({
                    content:
                        "Give me a hint for " + onEvent.payload?.questionTitle,
                    role: "user",
                });
            }
        }
    }, [onEvent]);

    const { t } = useTranslation();

    useEffect(() => {
        if (assistantData?.data?.initial_suggestions) {
            setSuggestions(assistantData.data.initial_suggestions);
        }
    }, [assistantData]);

    const localeMapper: { [key: string]: Locale } = {
        sl: "sl-SI",
        en: "en-US",
    };
    const locale = localeMapper[assistantData?.data?.language] || "en-US";
    const initialMessage =
        assistantData?.data?.initial_message ||
        t("assistant.initialMessage", "How can I help?");

    const currentChatId = getChatIdFromSessionStorage(
        isPublic,
        contentType,
        contentId
    );

    const { selectProps: chatMessageHistorySelectProps, queryResult } =
        useSelect<IChatMessageHistory>({
            resource: "chat-message-histories",
            optionLabel: "name",
            optionValue: "id",
            queryOptions: {
                enabled: !isPublic,
            },
            pagination: {
                pageSize: 100,
            },
            filters: [
                {
                    field: "content_type",
                    operator: "eq",
                    value: contentType,
                },
                {
                    field: "content_id",
                    operator: "eq",
                    value: contentId,
                },
            ],
        });
    const currentChat = queryResult.data?.data?.find(
        (chat: IChatMessageHistory) => chat.id === Number(currentChatId)
    ) || { name: "New Chat", value: -1 };

    const { data: messagesData } = useList({
        resource: "chat-messages",
        queryOptions: {
            enabled: !!currentChatId,
        },
        filters: [
            {
                field: "chat_message_history_id",
                operator: "eq",
                value: currentChatId,
            },
        ],
    });

    // sort and memoize messages
    const sortedMessages = useMemo(() => {
        if (messagesData?.data) {
            return messagesData.data.sort(
                (a: { id: number }, b: { id: number }) => a.id - b.id
            );
        }
        return [];
    }, [messagesData]);

    useEffect(() => {
        if (queryResult.data?.data && currentChatId !== "") {
            setSelectedValue({
                name: currentChat?.name,
                value: currentChatId,
            });
        }
    }, [queryResult.data?.data]);

    const { mutate: mutateCreate } = useCreate<IChatMessageHistory>();
    const invalidate = useInvalidate();

    const newChat = async () => {
        mutateCreate(
            {
                resource: "chat-message-histories",
                values: {
                    assistant: assistantId,
                    content_type: contentType,
                    content_id: contentId,
                },
            },
            {
                onSuccess: (data: { data: { id: string; name: string } }) => {
                    invalidate({
                        resource: "chat-messages",
                        invalidates: ["list"],
                    });
                    invalidate({
                        resource: "chat-message-histories",
                        invalidates: ["list"],
                    });
                    setChatIdInSessionStorage(
                        isPublic,
                        contentType,
                        contentId,
                        Number(data?.data?.id)
                    );
                    // also set the chat id in the chat select
                    const sel = {
                        value: Number(data?.data?.id),
                        name: data?.data?.name,
                    };
                    setSelectedValue(sel);
                },
            }
        );
    };

    type MessageExtra = Record<string, never>;

    const onMessageOver = (messageExtra: MessageExtra) => {
        const latestChat = chatRef.current?.getChatMessages();
        if (latestChat && latestChat.length > 0) {
            const lastMessage = latestChat[latestChat.length - 1];
            chatRef.current?.setMessageValue(
                lastMessage.id,
                "extra",
                messageExtra
            );
        }
    };

    const ChatToolbar = () => {
        const SelectChat = () => {
            return (
                <Col className="flex grow min-w-0">
                    <Select
                        {...chatMessageHistorySelectProps}
                        size="middle"
                        rootClassName="grow min-w-0"
                        defaultValue={{
                            value: currentChat?.id,
                            name: currentChat?.name,
                            // @ts-expect-error Ignore this error
                            label: currentChat?.name,
                        }}
                        value={selectedValue}
                        onChange={(value) => setSelectedValue(value)}
                        onSelect={(value) => {
                            setChatIdInSessionStorage(
                                isPublic,
                                contentType,
                                contentId,
                                Number(value)
                            );
                            invalidate({
                                resource: "chat-messages",
                                invalidates: ["list"],
                            });
                        }}
                    />
                </Col>
            );
        };

        const NewChatButton = ({ isMini = false }: { isMini?: boolean }) => {
            return (
                <>
                    {currentChat?.value !== -1 && (
                        <Col className="flex">
                            <Button onClick={newChat} icon={<PlusOutlined />}>
                                {isMini
                                    ? t("assistant.new", "New")
                                    : t("assistant.newChat", "New Chat")}
                            </Button>
                        </Col>
                    )}
                </>
            );
        };

        const HideButton = ({ isMobile = false }: { isMobile?: boolean }) => {
            if (enableHideButton) {
                return (
                    <>
                        {isMobile ? (
                            <div className="flex justify-end">
                                <CloseOutlined
                                    onClick={() => setAssistantVisible?.(false)}
                                    className="text-gray-500 hover:text-gray-700 cursor-pointer text-xl"
                                />
                            </div>
                        ) : (
                            <Col className="flex justify-end">
                                <CloseOutlined
                                    onClick={() => setAssistantVisible?.(false)}
                                    className="text-gray-500 hover:text-gray-700 cursor-pointer text-xl"
                                />
                            </Col>
                        )}
                    </>
                );
            }
        };
        return (
            <>
                {!isMobile ? (
                    <>
                        {!isPublic && (
                            <Row
                                gutter={[16, 16]}
                                justify="space-between"
                                align="middle"
                                className="w-full pb-4 flex-shrink-0 flex-row-reverse flex-wrap-reverse"
                            >
                                <HideButton />
                                <NewChatButton />
                                <SelectChat />
                            </Row>
                        )}
                    </>
                ) : (
                    <>
                        <Row
                            gutter={[16, 16]}
                            justify="space-between"
                            align="middle"
                            className="pb-4"
                        >
                            <SelectChat />
                            <NewChatButton isMini />
                            <div className="mr-4" />
                            <HideButton isMobile />
                        </Row>
                    </>
                )}
            </>
        );
    };

    if (isAssistantLoading || (!isPublic && queryResult.isLoading)) {
        return null;
    }

    return (
        <div className="flex flex-col" style={{ height: "80vh" }}>
            <ChatToolbar />
            <div className="flex-grow overflow-hidden">
                <ProChat
                    chatRef={chatRef}
                    style={{
                        maxWidth: "60rem",
                    }}
                    className="flex-grow h-full"
                    chatItemRenderConfig={{
                        actionsRender,
                    }}
                    initialChats={sortedMessages}
                    chats={sortedMessages}
                    showTitle={false}
                    helloMessage={initialMessage}
                    loading={false}
                    locale={locale}
                    actions={{
                        flexConfig: {},
                        render: undefined,
                    }}
                    messageItemExtraRender={(
                        message: ChatMessage,
                        type: "assistant" | "user"
                    ) => {
                        if (type === "assistant" && message.content) {
                            return (
                                <SourceButtons
                                    sources={message.extra?.sources}
                                    jumpToPage={jumpToPage}
                                    showPages={true}
                                    setAssistantVisible={setAssistantVisible}
                                    isMobile={isMobile}
                                />
                            );
                        }
                    }}
                    placeholder={t(
                        "assistant.placeholder",
                        "Ask me anything ..."
                    )}
                    transformToChatMessage={(preChatMessage) => {
                        const lines = preChatMessage.split("\n");
                        const messages = lines
                            .map((line) => {
                                if (!line.trim() || line === "") return null;
                                const jsonValue = line
                                    .replace(/^data: /, "")
                                    .trim();
                                try {
                                    const data = JSON.parse(jsonValue);
                                    if (data.chat_message_history_id) {
                                        setChatIdInSessionStorage(
                                            isPublic,
                                            contentType,
                                            contentId,
                                            data.chat_message_history_id
                                        );
                                    }
                                    if (data.message) {
                                        return data.message;
                                    }
                                    if (data.extra) {
                                        onMessageOver(data.extra);
                                    }
                                } catch (error) {
                                    console.error("Error parsing JSON:", error);
                                }
                                return null;
                            })
                            .filter(Boolean);

                        const messageContent = messages.join("");
                        return messageContent;
                    }}
                    userMeta={{
                        avatar: "",
                    }}
                    assistantMeta={{
                        avatar: assistantData?.data?.icon || "🤖",
                    }}
                    request={async (messages) => {
                        const latestMessage = messages[messages.length - 1];
                        const meta =
                            typeof latestMessage.content === "string"
                                ? latestMessage.content.match(
                                      /<META>(.*)<\/META>/
                                  )
                                : null;
                        if (meta) {
                            chatRef.current?.deleteMessage(latestMessage.id);
                        }
                        return sendMessageToServer(
                            apiUrl,
                            assistantId,
                            assistantUuid,
                            latestMessage,
                            isPublic,
                            contentType,
                            contentId
                        );
                    }}
                />
                <SuggestionComponent
                    suggestions={suggestions}
                    sendMessage={(msg) => {
                        setSuggestions([]);
                        chatRef.current?.sendMessage(msg);
                    }}
                />
            </div>
        </div>
    );
};
