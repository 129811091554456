import React, { useState } from "react";
import { usePermissions, useShow } from "@refinedev/core";
import { DeleteButton, EditButton, Show } from "@refinedev/antd";
import { Typography, Row, Col, Card, Breadcrumb, Button } from "antd";
import { MessageOutlined } from "@ant-design/icons";
import { YooptaContentValue } from "@yoopta/editor";
import styles from "./ContentPage.module.css";
import StarterKit from "@yoopta/starter-kit";
import { ContentPageType } from "./types";
import { Link, useParams } from "react-router-dom";
import { CreateChildPageButton } from "./edit";
import { Assistant, ContentType } from "components/assistant";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { isMobile } from "react-device-detect";

const { Title } = Typography;

export const ContentPageShow = () => {
    const { id } = useParams<{ id: string }>();
    const { queryResult } = useShow({
        resource: "content-pages",
        id,
    });
    const { data, isLoading } = queryResult;
    const record = data?.data;

    const { data: permissionsData } = usePermissions({});
    const isAdmin = permissionsData?.includes("admin");

    const [isAssistantVisible, setAssistantVisible] = useState(!isMobile);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    const breadcrumbItems = [
        { key: "home", title: <Link to="/content-pages">Home</Link> },
        ...(record?.breadcrumbs?.map(
            (crumb: { id?: string; title: string }, index: number) => ({
                key: crumb.id || `crumb-${index}`,
                title: crumb.id ? (
                    <Link to={`/content-pages/show/${crumb.id}`}>
                        {crumb.title}
                    </Link>
                ) : (
                    crumb.title
                ),
            })
        ) || []),
        { key: "current", title: record?.title },
    ];

    const Content = () => {
        return (
            <div>
                <Card className="p-0 m-0">
                    <div className="content-wrapper">
                        <StarterKit
                            value={record?.content as YooptaContentValue}
                            onChange={() => {}}
                            style={{ width: "100%" }}
                            readOnly
                        />
                    </div>
                </Card>
                <div
                    className={styles.childPagesSection}
                    style={{
                        overflow: "hidden",
                    }}
                >
                    {record?.children && <Title level={4}>Child Pages</Title>}
                    <Row gutter={[16, 16]}>
                        {record?.children?.map((child: ContentPageType) => (
                            <Col
                                key={child.id}
                                xs={24}
                                sm={isAssistantVisible ? 24 : 12}
                                md={isAssistantVisible ? 16 : 8}
                                lg={isAssistantVisible ? 12 : 6}
                            >
                                <Link to={`/content-pages/show/${child.id}`}>
                                    <Card
                                        hoverable
                                        className={styles.childPageCard}
                                        cover={
                                            <div
                                                className={
                                                    styles.thumbnailContainer
                                                }
                                            >
                                                <img
                                                    alt={child.title}
                                                    src={
                                                        child.thumbnail ||
                                                        `https://picsum.photos/seed/${child.id}/300/200`
                                                    }
                                                    className={styles.thumbnail}
                                                />
                                            </div>
                                        }
                                    >
                                        <Card.Meta title={child.title} />
                                    </Card>
                                </Link>
                            </Col>
                        ))}
                    </Row>
                </div>
            </div>
        );
    };

    return (
        <div className="relative h-[calc(100vh-180px)]">
            <Show
                key={record?.id}
                title={record?.title}
                breadcrumb={<Breadcrumb items={breadcrumbItems} />}
                headerButtons={
                    isAdmin
                        ? [
                              <DeleteButton key="delete" />,
                              <CreateChildPageButton
                        key="create-child"
                        currentPage={queryResult?.data?.data}
                    />,
                        <EditButton key="edit" />,
                    ] : []
                }
                contentProps={{
                    bordered: false,
                    style: {
                        backgroundColor: "transparent",
                        boxShadow: "none",
                    },
                    styles: {
                        body: {
                            padding: "0px",
                            margin: "0px",
                        },
                    },
                }}
            >
                {!isMobile ? (
                    <PanelGroup direction="horizontal" className="h-full">
                        <Panel
                            id="P-document"
                            minSize={50}
                            className="!overflow-y-auto"
                        >
                            <Content />
                        </Panel>
                        {isAssistantVisible && record.assistant_id && (
                            <>
                                <PanelResizeHandle className="mx-1 w-2 hover:bg-gray-200 transition-colors" />
                                <Panel
                                    id="P-assistant"
                                    minSize={30}
                                    maxSize={80}
                                >
                                    <div className="h-full">
                                        <Assistant
                                            assistantId={record.assistant_id}
                                            contentType={ContentType.PAGE}
                                            contentId={record.id}
                                            enableHideButton
                                            setAssistantVisible={
                                                setAssistantVisible
                                            }
                                        />
                                    </div>
                                </Panel>
                            </>
                        )}
                    </PanelGroup>
                ) : (
                    <>
                        <Content />
                        <div
                            className={`fixed inset-0 z-50 bg-gray-200 p-6 w-full ${
                                isAssistantVisible
                                    ? "opacity-100 pointer-events-auto"
                                    : "opacity-0 pointer-events-none"
                            } transition-opacity duration-300`}
                        >
                            <Assistant
                                assistantId={record.assistant_id}
                                contentType={ContentType.PAGE}
                                contentId={record.id}
                                enableHideButton
                                setAssistantVisible={setAssistantVisible}
                                isMobile
                            />
                        </div>
                    </>
                )}
            </Show>
            {!isAssistantVisible && record.assistant_id && (
                <Button
                    type="primary"
                    shape="circle"
                    icon={<MessageOutlined />}
                    size="large"
                    className="fixed bottom-4 right-4 z-50"
                    onClick={() => setAssistantVisible(!isAssistantVisible)}
                />
            )}
        </div>
    );
};
