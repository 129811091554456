import {
    DeleteButton,
    EditButton,
    List,
    ShowButton,
    useTable,
} from "@refinedev/antd";
import { BaseRecord, useInvalidate } from "@refinedev/core";
import { Button, Space, Table, Tag } from "antd";
import { CloneInstanceButton } from "./form";
import { useGetIdentity } from "@refinedev/core";
import { IIdentity } from "../../authProvider";
import { CaseQuestionType } from "./types";
import { FileAddOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

export const CaseList = () => {
    const { tableProps } = useTable({
        syncWithLocation: true,
    });

    const { data: identity } = useGetIdentity<IIdentity>();

    const invalidate = useInvalidate();

    return (
        <List
            canCreate={identity?.is_superuser && !identity?.regular_user_mode}
        >
            <Table {...tableProps} rowKey="id">
                <Table.Column dataIndex="id" title={"ID"} />
                <Table.Column dataIndex="name" title={"Name"} />
                <Table.Column
                    dataIndex="category_label"
                    title={"Category"}
                    // Render as tag
                    render={(category_label) => <Tag color="blue">{category_label}</Tag>}
                />
                <Table.Column
                    dataIndex="questions"
                    title={"Questions"}
                    render={(questions: CaseQuestionType[]) =>
                        questions?.length
                    }
                />
                <Table.Column
                    title={"Actions"}
                    dataIndex="actions"
                    align="right"
                    render={(_, record: BaseRecord) => (
                        <Space>
                            <Link to={`/cases/attempts/${record.id}/`}>
                                <Button size="small" icon={<FileAddOutlined />}>
                                    Attempts
                                </Button>
                            </Link>
                            <CloneInstanceButton
                                hideText
                                resource="cases"
                                id={record?.id as unknown as string}
                                className=""
                                onClick={() => {
                                    invalidate({
                                        resource: "cases",
                                        invalidates: [
                                            "resourceAll",
                                            "all",
                                            "logList",
                                            "list",
                                        ],
                                    });
                                }}
                            />

                            <ShowButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                            <EditButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                            <DeleteButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                        </Space>
                    )}
                />
            </Table>
        </List>
    );
};
