import { DateField, List, useTable } from "@refinedev/antd";
import { BaseRecord, useCreate } from "@refinedev/core";
import { Button, Space, Table } from "antd";
import { Link, useParams } from "react-router-dom";
import { EyeOutlined, FileAddOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

export const CaseAttemptsList = () => {
    const { id } = useParams<{ id: string }>();
    const { tableProps } = useTable({
        syncWithLocation: true,
        resource: `case-attempts-list/${id}`,
        queryOptions: {
            enabled: !!id,
        },
    });
    const navigate = useNavigate();

    const { mutate: mutateCreate } = useCreate();

    const onCreateAttempt = () => {
        mutateCreate(
            {
                resource: "case-attempts",
                values: {
                    case: id,
                },
            },
            {
                onSuccess: (data: { data: { id: number } }) => {
                    navigate(`/cases/${id}/attempt/${data?.data?.id}`);
                },
            }
        );
    };

    return (
        <List
            title={`My attempts for case ${id}`}
            resource="case-attempts"
            headerButtons={[
                <Button
                    key="create"
                    type="primary"
                    icon={<FileAddOutlined />}
                    onClick={onCreateAttempt}
                >
                    New Case Attempt
                </Button>,
            ]}
        >
            <Table {...tableProps} rowKey="id">
                <Table.Column dataIndex="id" title={"ID"} />
                <Table.Column
                    dataIndex="created_at"
                    title={"Created At"}
                    render={(value) => (
                        <DateField
                            value={value}
                            format="MMMM Do YYYY, hh:mm:ss"
                        />
                    )}
                />
                <Table.Column
                    title={"Actions"}
                    dataIndex="actions"
                    align="right"
                    render={(_, record: BaseRecord) => (
                        <Space>
                            <Link to={`/cases/${id}/attempt/${record.id}`}>
                                <Button
                                    icon={<EyeOutlined />}
                                    size="small"
                                ></Button>
                            </Link>
                        </Space>
                    )}
                />
            </Table>
        </List>
    );
};
