import {
    DeleteButton,
    EditButton,
    List,
    ShowButton,
    useTable,
} from "@refinedev/antd";
import { BaseRecord, useInvalidate } from "@refinedev/core";
import { Space, Table } from "antd";
import { CloneInstanceButton } from "./form";
import { useGetIdentity } from "@refinedev/core";
import { IIdentity } from "../../authProvider";

export const QuizList = () => {
    const { tableProps } = useTable({
        syncWithLocation: true,
    });

    const { data: identity } = useGetIdentity<IIdentity>();

    const invalidate = useInvalidate();

    return (
        <List
        canCreate={identity?.is_superuser && !identity?.regular_user_mode}

        >
            <Table {...tableProps} rowKey="id">
                <Table.Column
                    dataIndex="id"
                    title={"ID"}
                />
                <Table.Column dataIndex="name" title={"Name"} />
                <Table.Column dataIndex="question_ids" title={"Questions"} render={
                    (question_ids: string[]) => question_ids?.length
                }  />
                {/* <Table.Column
                    dataIndex="is_draft"
                    title={"Status"}
                    render={(isDraft: boolean) => (isDraft ? <Tag color="blue">Draft</Tag> : <Tag color="green">Published</Tag>)}                /> */}
                <Table.Column
                    title={"Actions"}
                    dataIndex="actions"
                    align="right"
                    render={(_, record: BaseRecord) => (
                        <Space>
                            <CloneInstanceButton
                                hideText
                                resource="quizzes"
                                id={record?.id as unknown as string}
                                className=""
                                onClick={() => {
                                    invalidate({
                                        resource: "quizzes",
                                        invalidates: ["resourceAll", "all", "logList", "list"]
                                    });
                                }}
                            />
                            <ShowButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                            <EditButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                            <DeleteButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                        </Space>
                    )}
                />
            </Table>
        </List>
    );
};
