import { Form, FormProps, Input, Select } from "antd";
import {
    CaseType,
    CaseQuestionType
} from "./types";
import { MarkdownEditor } from "components/markdown-editor";
import { Button, Row } from "antd";
import { DeleteButton, useForm, useSelect } from "@refinedev/antd";
import { Collapse } from "antd";
import {
    useApiUrl,
    useCreate,
    useCustomMutation,
    useInvalidate,
    useMany,
    useOne,
    useUpdate,
} from "@refinedev/core";
import { CopyOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";

import { FC, MouseEventHandler } from "react";

interface Entity {
    order: number;
    id: number;
    uuid: string;
}

interface UpDownOrderButtonsProps {
    resource: string;
    // eslint-disable-next-line @typescript-eslint/ban-types
    mutateUpdate: Function;
    entity: Entity;
    entities: Entity[];
    index: number;
}

const UpDownOrderButtons: FC<UpDownOrderButtonsProps> = ({
    resource,
    mutateUpdate,
    entity,
    entities,
    index,
}) => {
    const handleUpClick: MouseEventHandler<HTMLButtonElement> = () => {
        const currentOrder = entity.order;

        mutateUpdate({
            resource,
            id: entity.id,
            values: {
                order: entities[index - 1].order,
            },
            successNotification: () => {
                return {
                    type: "hidden",
                };
            },
        });

        mutateUpdate({
            resource,
            id: entities[index - 1].id,
            values: {
                order: currentOrder,
            },
            successNotification: () => {
                return {
                    type: "hidden",
                };
            },
        });
    };

    const handleDownClick: MouseEventHandler<HTMLButtonElement> = () => {
        const currentOrder = entity.order;

        mutateUpdate({
            resource,
            id: entity.id,
            values: {
                order: entities[index + 1].order,
            },
            successNotification: () => {
                return {
                    type: "hidden",
                };
            },
        });

        mutateUpdate({
            resource,
            id: entities[index + 1].id,
            values: {
                order: currentOrder,
            },
            successNotification: () => {
                return {
                    type: "hidden",
                };
            },
        });
    };

    return (
        <>
            <Button
                disabled={index === 0}
                onClick={handleUpClick}
                size="small"
                className="mr-2"
            >
                <UpOutlined />
            </Button>
            <Button
                disabled={index === entities.length - 1}
                onClick={handleDownClick}
                size="small"
                className="mr-2"
            >
                <DownOutlined />
            </Button>
        </>
    );
};

export default UpDownOrderButtons;

export const CloneInstanceButton = ({
    resource,
    id,
    hideText = false,
    className = "",
    onClick = () => {},
}: {
    resource: string;
    id: string;
    hideText: boolean;
    className: string;
    // eslint-disable-next-line @typescript-eslint/ban-types
    onClick: Function;
}) => {
    const apiUrl = useApiUrl();
    const { mutate } = useCustomMutation();

    const cloneInstance = () => {
        mutate({
            url: `${apiUrl}/${resource}/${id}/clone/`,
            method: "post",
        });
    };

    return (
        <Button
            icon={<CopyOutlined />}
            size="small"
            onClick={() => {
                cloneInstance();
                if (onClick) {
                    onClick();
                }
            }}
            className={className}
        >
            {!hideText && "Clone"}
        </Button>
    );
};

const CaseQuestionForm = ({ questionId }: { questionId: number }) => {
    const invalidate = useInvalidate();

    const { queryResult, formProps } = useForm({
        action: "edit",
        resource: "case-questions",
        id: questionId,
        autoSave: {
            enabled: true,
            debounce: 2500,
        },
        onMutationSuccess: () => {
            invalidate({
                resource: "cases",
                invalidates: ["all"],
            });
        },
    });



    if (queryResult.isLoading) {
        return <p>Loading...</p>;
    }

    return (
        <>
            <Form {...formProps} layout="vertical">
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Please input the question title!",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Question"
                    name="question"
                    rules={[
                        {
                            required: true,
                            message:
                                "Please input the question!",
                        },
                    ]}
                >
                    <MarkdownEditor preview="edit" />
                </Form.Item>
                <Form.Item
                    label="Possible Answer"
                    name="possible_answer"
                    rules={[
                        {
                            required: true,
                            message:
                                "Please input the explanation of the possible solution!",
                        },
                    ]}
                >
                    <MarkdownEditor preview="edit" />
                </Form.Item>
            </Form>
        </>
    );
};

const QuestionsTable = ({
    questions,
    invalidate,
    mutateUpdate,
}: {
    questions: CaseQuestionType[];
    // eslint-disable-next-line @typescript-eslint/ban-types
    invalidate: Function;
    // eslint-disable-next-line @typescript-eslint/ban-types
    mutateUpdate: Function;
}) => {
    return (
        <Collapse accordion>
            {questions.map((question: CaseQuestionType, index) => (
                <Collapse.Panel
                    collapsible="icon"
                    header={question.name}
                    key={question.id}
                    extra={
                        <>
                            <UpDownOrderButtons
                                resource="case-questions"
                                mutateUpdate={mutateUpdate}
                                entity={question}
                                entities={questions}
                                index={index}
                            />
                            <CloneInstanceButton
                                hideText
                                resource="case-questions"
                                id={question.id as unknown as string}
                                className="mr-2"
                                onClick={() => {
                                    invalidate({
                                        resource: "cases",
                                        invalidates: ["resourceAll"],
                                    });
                                }}
                            />
                            <DeleteButton
                                hideText
                                size="small"
                                resource="case-questions"
                                recordItemId={question.id}
                                invalidates={["resourceAll"]}
                                onSuccess={() => {
                                    invalidate({
                                        resource: "cases",
                                        invalidates: ["resourceAll"],
                                    });
                                }}
                            />
                        </>
                    }
                >
                    <CaseQuestionForm questionId={question.id} />
                </Collapse.Panel>
            ))}
        </Collapse>
    );
};

interface ICategory {
    value: string;
    label: string;
  }

export const CaseForm = ({
    formProps,
    data,
}: {
    formProps: FormProps;
    data: CaseType | undefined;
}) => {
    const defaultData = {};

    const { data: caseQuery } = useOne({
        resource: "cases",
        id: data?.id,
        queryOptions: { enabled: !!data?.id },
    });

    const { data: questionsQuery } = useMany({
        resource: "case-questions",
        ids: caseQuery?.data?.questions?.map((q: CaseQuestionType) => q.id) || [],
    });

    const { mutate } = useCreate();
    const { mutate: mutateUpdate } = useUpdate();
    const invalidate = useInvalidate();

    const { selectProps: assistantSelectProps } = useSelect({
        resource: "assistants",
        optionLabel(item) {
            return item?.name;
        },
    });

    const { selectProps: categorySelectProps } = useSelect<ICategory>({
        resource: "case-categories",
        optionLabel: "label",
        optionValue: "value",
    });

    return (
        <>
            <Form
                {...formProps}
                layout="vertical"
                initialValues={data || defaultData}
            >
                <Form.Item
                    label={"Name"}
                    name={["name"]}
                    rules={[{ required: true }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Category"
                    name="category"
                    rules={[
                        {
                            required: true,
                        }
                    ]}
                >
                    <Select {...categorySelectProps} />
                </Form.Item>
                <Form.Item
                    label={"Description"}
                    name={["description"]}
                    rules={[{ required: true }]}
                >
                    <MarkdownEditor preview="edit" />
                </Form.Item>
                <Form.Item
                    label={"Assistant"}
                    name={["assistant"]}
                    rules={[{ required: true }]}
                >
                    <Select {...assistantSelectProps} />
                </Form.Item>
            </Form>
            {!!data && (
                <>
                    <div>
                        <h2>Questions</h2>
                        <QuestionsTable
                            questions={
                                questionsQuery?.data?.sort(
                                    (
                                        a: CaseQuestionType,
                                        b: CaseQuestionType
                                    ) => a.order - b.order
                                ) || []
                            }
                            invalidate={invalidate}
                            mutateUpdate={mutateUpdate}
                        />
                        <Row
                            justify="center"
                            align="middle"
                            gutter={[16, 8]}
                            className="mt-8"
                        >

                                <Button
                                    type="primary"
                                    onClick={() => {
                                        mutate({
                                            resource:
                                                "case-questions",
                                            values: {
                                                case: data?.id,
                                            },
                                            successNotification: () => {
                                                return {
                                                    type: "hidden",
                                                };
                                            },
                                        });
                                        invalidate({
                                            resource: "cases",
                                            invalidates: ["resourceAll"],
                                        });
                                    }}
                                >
                                    Add Question
                                </Button>
                        </Row>
                    </div>
                </>
            )}
            {!data && (
                <h4>Create the Case first to be able to add questions ...</h4>
            )}
        </>
    );
};
