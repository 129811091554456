import { Edit, useForm } from "@refinedev/antd";
import { ContentVideoType } from "./types";
import { ContentVideoForm } from "./form";

export const ContentVideoEdit = () => {
    const {formProps, queryResult, saveButtonProps} = useForm();

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <ContentVideoForm data={queryResult?.data?.data as ContentVideoType} formProps={formProps}/>
        </Edit>
    );
};
