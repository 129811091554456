import {
    DeleteButton,
    EditButton,
    ShowButton,
    useTable,
} from "@refinedev/antd";
import { Table, Space } from "antd";
import { BaseRecord, useGetIdentity, CrudFilters } from "@refinedev/core";
import { IIdentity } from "../../authProvider";
import { t } from "i18next";

import React from "react";
import { useList } from "@refinedev/core";
import { Card, Row, Col } from "antd";
import { Link } from "react-router-dom";
import { ContentPageType } from "./types";
import { ContentList } from "components/layout/content-list";

const ContentPageCardList: React.FC = () => {
    const { data, isLoading } = useList<ContentPageType>({
        resource: "content-pages",
        filters: [
            {
                field: "parent",
                operator: "eq",
                value: "null",
            },
        ],
    });

    if (isLoading) {
        return <div>Loading...</div>;
    }

    const topLevelPages =
        data?.data.filter((page: ContentPageType) => !page.parent) || [];

    return (
        <div className="p-4">
            <Row gutter={[16, 16]}>
                {topLevelPages.map((page: ContentPageType) => (
                    <Col key={page.id} xs={24} sm={12} md={8} lg={8} xl={8}>
                        <Link to={`/content-pages/show/${page.id}`}>
                            <Card
                                hoverable
                                cover={
                                    <img
                                        alt={page.title}
                                        src={
                                            page.thumbnail ||
                                            `https://picsum.photos/seed/${page.id}/300/200`
                                        }
                                        className="h-40 object-cover"
                                    />
                                }
                                className="h-full"
                            >
                                <Card.Meta
                                    title={page.title}
                                    description={
                                        page.is_draft ? "Draft" : "Published"
                                    }
                                />
                            </Card>
                        </Link>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

const ContentPageTableList = () => {
    const { tableProps, tableQueryResult } = useTable<ContentPageType>({
        syncWithLocation: true,
        resource: "content-pages",
        filters: {
            permanent: [
                {
                    field: "parent",
                    operator: "eq",
                    value: "null",
                },
            ] as CrudFilters,
        },
    });
    const { data: identity } = useGetIdentity<IIdentity>();

    if (tableQueryResult.isLoading || !identity) {
        return <div>Loading...</div>;
    }

    const data = tableQueryResult.data?.data || [];

    const columns = [
        { title: "Title", dataIndex: "title", key: "title" },
        {
            title: "Draft",
            dataIndex: "is_draft",
            key: "is_draft",
            render: (value: boolean) => (value ? "Yes" : "No"),
        },
        {
            title: "Last Updated",
            dataIndex: "updated_at",
            key: "updated_at",
            render: (value: string) => new Date(value).toLocaleString(),
        },
        {
            title: "Children",
            key: "children",
            render: (record: ContentPageType) =>
                record.children?.length ? record.children.length : "None",
        },
        {
            title: "Assistant",
            dataIndex: "assistant_label",
            key: "assistant_label",
            render: (value: string) => value,
        },
        {
            title: t("tableHeaders.actions", "Actions"),
            key: "actions",
            render: (_: React.ReactNode, record: BaseRecord) => (
                <Space>
                    <EditButton
                        hideText
                        size="small"
                        recordItemId={record.id}
                    />
                    <ShowButton
                        hideText
                        size="small"
                        recordItemId={record.id}
                    />
                    <DeleteButton
                        hideText
                        size="small"
                        recordItemId={record.id}
                    />
                </Space>
            ),
        },
    ];

    return (
        <Table
            {...tableProps}
            dataSource={data}
            columns={columns}
            rowKey="id"
        />
    );
};

export const ContentPageList: React.FC = () => {
    return (
        <ContentList
            cardComponent={ContentPageCardList}
            tableComponent={ContentPageTableList}
            cookieKey="content-pages-view"
        />
    );
};
