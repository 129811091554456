import React from "react";
import { Edit, useForm, SaveButton, DeleteButton } from "@refinedev/antd";
import { useCreate, useNavigation, useInvalidate } from "@refinedev/core";
import { ContentPageType } from "./types";
import { ContentPageForm } from "./form";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";

export const CreateChildPageButton: React.FC<{ currentPage: ContentPageType }> = ({ currentPage }) => {
    const { mutate: createPage } = useCreate();
    const { edit } = useNavigation();
    const invalidate = useInvalidate();

    const handleCreateChild = () => {
        createPage(
            {
                resource: "content-pages",
                values: {
                    parent: currentPage.id,
                    title: `Child of ${currentPage.title}`,
                },
            },
            {
                onSuccess: (data: { data: { id: number } }) => {
                    // Invalidate the parent page's show cache
                    invalidate({
                        resource: "content-pages",
                        invalidates: ["all"],
                        id: currentPage.id,
                    });
                    // Redirect to the edit page of the new child
                    edit("content-pages", data.data.id);
                },
            }
        );
    };

    return (
        <Button onClick={handleCreateChild} icon={<PlusOutlined />}>
            Create Child Page
        </Button>
    );
};

export const ContentPageEdit: React.FC = () => {
    const { formProps, saveButtonProps, queryResult } =
        useForm<ContentPageType>({
            redirect: "show",
        });

    return (
        <Edit
            footerButtons={[]}
            headerButtons={[
                <DeleteButton key="delete-button" />,
                <CreateChildPageButton currentPage={queryResult?.data?.data} />,
                <SaveButton {...saveButtonProps} />,
            ]}
        >
            <ContentPageForm
                formProps={formProps}
                initialValues={queryResult?.data?.data}
                isEditing={true}
            />
        </Edit>
    );
};
