import Markdown from "react-markdown";
import "../../components/markdown.css";
import { EditButton, Show } from "@refinedev/antd";
import { useShow } from "@refinedev/core";
import { MultipleChoiceAnswerType, MultipleChoiceQuestionType } from "./types";
import { Button, Card, Radio, RadioChangeEvent, notification } from "antd";
import { useEffect, useState } from "react";
import { Assistant, ContentType } from "components/assistant";
import { PanelGroup, Panel, PanelResizeHandle } from "react-resizable-panels";
import { CopyFilled, MessageOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const MultipleChoiceQuestion = ({
    id,
    selectedAnswer,
    setSelectedAnswer,
    checkState,
    hintCallback,
    explainCallback,
}: {
    id: number;
    selectedAnswer: MultipleChoiceAnswerType;
    setSelectedAnswer: (value: MultipleChoiceAnswerType | null) => void;
    checkState: "correct" | "incorrect" | "unanswered";
    hintCallback: (id: number) => void;
    explainCallback: (questionUuid: string, answerUuid: string) => void;
}) => {
    const { queryResult } = useShow({
        resource: "multiple-choice-questions",
        id,
    });
    const { data, isLoading } = queryResult;

    const record = data?.data;

    const handleChange = (e: RadioChangeEvent) => {
        const answer = e.target.value;
        setSelectedAnswer(answer);
    };

    if (isLoading || !record) {
        return null;
    }

    return (
        <div className="mb-8">
            <div className="flex justify-between">
                <h3>{record.title}</h3>
                <div className="flex-1" />
                {checkState === "unanswered" && (
                    <Button
                        size="small"
                        className="mr-4"
                        onClick={() => hintCallback(id)}
                    >
                        Hint
                    </Button>
                )}
                {checkState === "correct" && (
                    <p style={{ color: "green" }}>Correct!</p>
                )}
                {checkState === "incorrect" && (
                    <p style={{ color: "red" }}>Incorrect!</p>
                )}
                <div className="mr-4" />
                {(checkState === "correct" || checkState === "incorrect") && (
                    <Button
                        type="primary"
                        className="mr-4"
                        size="small"
                        onClick={() =>
                            explainCallback(record.uuid, selectedAnswer.uuid)
                        }
                    >
                        Explain
                    </Button>
                )}
            </div>
            <p>{record.content}</p>
            <Radio.Group
                value={selectedAnswer}
                onChange={handleChange}
                style={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                {record.answers.map((answer: MultipleChoiceAnswerType) => (
                    <Radio key={answer.id} value={answer} className="mb-2">
                        {answer.answer}
                    </Radio>
                ))}
            </Radio.Group>
        </div>
    );
};

export const QuizShow = () => {
    const { queryResult } = useShow({});
    const { data, isLoading } = queryResult;

    const { t } = useTranslation();

    const record = data?.data;

    const [isAssistantVisible, setAssistantVisible] = useState(true);

    const [selectedAnswers, setSelectedAnswers] = useState<{
        [key: string]: MultipleChoiceAnswerType | null;
    }>({});

    const [checkStates, setCheckStates] = useState<{
        [key: string]: "correct" | "incorrect" | "unanswered";
    }>({});

    useEffect(() => {
        if (!record) {
            return;
        }
        setSelectedAnswers(
            record.question_ids.reduce((acc: null[], questionId: number) => {
                acc[questionId] = null;
                return acc;
            }, {})
        );
        setCheckStates(
            record.question_ids.reduce((acc: string[], questionId: number) => {
                acc[questionId] = "unanswered";
                return acc;
            }, {})
        );
    }, [record]);

    const handleAnswerSelect = (
        questionId: number,
        answer: MultipleChoiceAnswerType | null
    ) => {
        setSelectedAnswers((prev) => ({ ...prev, [questionId]: answer }));
    };

    const handleCheckAll = () => {
        setCheckStates((prev) => {
            const newCheckStates = { ...prev };
            for (const questionId in selectedAnswers) {
                newCheckStates[questionId] = selectedAnswers[questionId]
                    ?.is_correct
                    ? "correct"
                    : "incorrect";
            }
            return newCheckStates;
        });
    };

    const [onAssistantEvent, setOnAssistantEvent] = useState<{
        type: "explain" | "hint";
        payload: {
            question?: string;
            answer?: string;
            questionTitle?: string;
            questionUuid?: string;
            answerUuid?: string;
        };
    } | null>(null);

    const handleExplain = (questionUuid: string, answerUuid: string) => {
        setAssistantVisible(true);
        const question: MultipleChoiceQuestionType = record?.questions.find(
            (question: MultipleChoiceQuestionType) =>
                question.uuid === questionUuid
        );
        setOnAssistantEvent({
            type: "explain",
            payload: {
                questionUuid,
                question: question.content,
                answer: question.answers.find((answer) => answer.is_correct)
                    ?.answer,
                questionTitle: question.title,
                answerUuid: answerUuid,
            },
        });
    };

    const handleHint = (id: number) => {
        setAssistantVisible(true);
        const question: MultipleChoiceQuestionType = record?.questions.find(
            (question: MultipleChoiceQuestionType) => question.id === id
        );
        setOnAssistantEvent({
            type: "hint",
            payload: {
                question: question.content,
                questionTitle: question.title,
                questionUuid: question.uuid,
            },
        });
    };

    const copyQuizPublicLink = () => {
        const publicLink = `${window.location.origin}/quiz-public/show/${data?.data.uuid}`;
        navigator.clipboard.writeText(publicLink);
        notification.success({
            message: "Public link copied to clipboard",
            description: publicLink,
            duration: 2,
        });
    };

    if (isLoading || !record) {
        return null;
    }

    return (
        <div
            style={{
                height: "80vh",
            }}
        >
            <Show
                breadcrumb
                title={record.name}
                isLoading={isLoading}
                contentProps={{
                    style: {
                        backgroundColor: "#F5F5F5",
                        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0)",
                        margin: "-24px",
                        marginTop: "-16px",
                    },
                }}
                headerButtons={({ editButtonProps }) => {
                    return (
                        <>
                            <Button
                                size="middle"
                                onClick={copyQuizPublicLink}
                                icon={<CopyFilled />}
                            >
                                {t("actions.copyPublicLink", "Copy Public Link")}
                            </Button>
                            <EditButton {...editButtonProps}/>
                            <Button
                                type="primary"
                                onClick={() =>
                                    setAssistantVisible(!isAssistantVisible)
                                }
                                icon={<MessageOutlined />}
                            >
                                {t(
                                    "assistant.toggleAssistant",
                                    "Toggle Assistant"
                                )}
                            </Button>
                        </>
                    );
                }}
            >
                <PanelGroup direction="horizontal">
                    <Panel id="P-quiz" minSize={40} defaultSize={60}>
                        <Card>
                            <div
                                className="overflow-y-auto"
                                style={{ height: "70vh" }}
                            >
                                <Markdown className="mb-8">
                                    {record.description}
                                </Markdown>

                                {record?.question_ids.map(
                                    (questionId: number) => (
                                        <MultipleChoiceQuestion
                                            key={questionId}
                                            id={questionId}
                                            checkState={checkStates[questionId]}
                                            hintCallback={handleHint}
                                            explainCallback={handleExplain}
                                            selectedAnswer={
                                                selectedAnswers[questionId] || {
                                                    id: -1,
                                                    uuid: "",
                                                    answer: "",
                                                    is_correct: false,
                                                }
                                            }
                                            setSelectedAnswer={(answer) =>
                                                handleAnswerSelect(
                                                    questionId,
                                                    answer
                                                )
                                            }
                                        />
                                    )
                                )}
                            </div>
                            <div className="flex justify-end">
                                <Button
                                    onClick={handleCheckAll}
                                    type="primary"
                                    className="mr-2"
                                    disabled={Object.values(
                                        selectedAnswers
                                    ).some((answer) => answer === null)}
                                >
                                    Check All
                                </Button>
                            </div>
                        </Card>
                    </Panel>
                    {isAssistantVisible && (
                        <>
                            <PanelResizeHandle />
                            <Panel id="P-assistant" minSize={30}>
                                <Assistant
                                    assistantId={record.assistant}
                                    contentType={ContentType.QUIZ}
                                    contentId={record.id}
                                    onEvent={onAssistantEvent}
                                />
                            </Panel>
                        </>
                    )}
                </PanelGroup>
            </Show>
        </div>
    );
};
