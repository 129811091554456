export enum Feature {
    Dashboard = "dashboard",
    Users = "users",
    UserGroups = "user-groups",
    Assistants = "assistants",
    AssistantsAdmin = "assistants-admin",
    Videos = "content-videos",
    Documents = "content-documents",
    Quizzes = "quizzes",
    Cases = "cases",
    ContentPages = "content-pages",
    ContentPagesAdmin = "content-pages-admin",
    GroupBasedCustomization = "group-based-customization",
    PublicContentLinks = "public-content-links",
    Evaluations = "evaluations",
}

interface FeatureObject {
    id: number;
    name: string;
    user_group: number;
}

export function mapAvailableFeatures(features: FeatureObject[]): Feature[] {
    return features
        .map((feature) => {
            switch (feature.name) {
                case "videos":
                    return Feature.Videos;
                case "documents":
                    return Feature.Documents;
                case "quizzes":
                    return Feature.Quizzes;
                case "users":
                    return Feature.Users;
                case "user-groups":
                    return Feature.UserGroups;
                case "dashboard":
                    return Feature.Dashboard;
                case "assistants":
                    return Feature.Assistants;
                case "assistants-admin":
                    return Feature.AssistantsAdmin;
                case "cases":
                    return Feature.Cases;
                case "content-pages":
                    return Feature.ContentPages;
                case "content-pages-admin":
                    return Feature.ContentPagesAdmin;
                case "group-based-customization":
                    return Feature.GroupBasedCustomization;
                case "public-content-links":
                    return Feature.PublicContentLinks;
                case "evaluations":
                    return Feature.Evaluations;
                default:
                    return null; // Handle unknown features
            }
        })
        .filter((feature) => feature !== null) as Feature[]; // Filter out null values and cast to Feature[]
}

export function mapFeatureStringsToFeatures(features: string[]): Feature[] {
    return features.map((feature) => {
        switch (feature) {
            case "dashboard":
                return Feature.Dashboard;
            case "users":
                return Feature.Users;
            case "user-groups":
                return Feature.UserGroups;
            case "assistants":
                return Feature.Assistants;
            case "content-documents":
                return Feature.Documents;
            case "content-pages":
                return Feature.ContentPages;
            case "public-content-links":
                return Feature.PublicContentLinks;
            case "evaluations":
                return Feature.Evaluations;
            default:
                return null;
        }
    }).filter((feature) => feature !== null) as Feature[];
}

export function mapTenantFeaturesToChoices(tenantFeatures: string[]): string[] {
    const featureMap: { [key: string]: string } = {
        dashboard: "dashboard",
        users: "users",
        "user-groups": "user-groups",
        "content-documents": "documents",
        "group-based-customization": "group-based-customization",
        assistants: "assistants",
        "content-pages": "content-pages",
        "public-content-links": "public-content-links",
        evaluations: "evaluations",
    };

    return tenantFeatures.map((feature) => featureMap[feature]);
}
